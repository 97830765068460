import React from 'react';

import { Route, Redirect, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';

import { DiscoverPage } from './components/products/product-list/ProductList';
import ProductPage from './components/products/product/ProductPage';
import { TeamSubscriptionList } from './components/subscriptions/team-subscriptions-list/TeamSubscriptionList';
/**
 * @class ProxyRoutes
 * @description For storing internal page redirection inside API Summary Module
 */
class MarketplaceRoutes extends React.Component {

     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     
     render() {
          return (
               <div className="proxy"  >
                    <Switch>
                        <Route path="/home/marketplace/product/:productName" render={() => (<ProductPage />)} />
                        <Route path="/home/marketplace/subscriptions" render={() => (<TeamSubscriptionList />)} />                        <Route path="/home/marketplace/developer-portal" render={() => (<DiscoverPage />)} />
                        <Route exact path="/home/marketplace" render={() => (<Redirect to="/home/marketplace/developer-portal" />)} />
                        <Route path="*"  render={()=><Redirect to ="/home/marketplace/developer-portal" />}/>
                    </Switch>
               </div>
          );
     }
}

export default withRouter(MarketplaceRoutes);