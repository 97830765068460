import React, { Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import ProductDiscovery from './ProductDiscovery';
import ProductSummary from './ProductSummary';
import { GenericPageHeaderSkeleton } from 'legacy-components/page-headers/GenericPageHeader';


export const ProductConsumerRoutes = () => {
    return (
        <Suspense fallback={<GenericPageHeaderSkeleton />}>
            <Switch>
                <Route exact path="/home/consumer/products" render={() => (<Redirect to="/home/consumer/products/list" />)} />

                <Route exact path="/home/consumer/products/list" render={() => (
                    <ProductDiscovery/>
                )} />

                <Route exact path="/home/consumer/products/:productId" render={() => (
                        <ProductSummary />
                )} />
            </Switch>
        </Suspense>
    )
}


export default ProductConsumerRoutes

