import React, { useCallback, useContext } from "react";
import {
  IconButton,
  Tooltip,
  DialogContent,
  DialogActions,
  //Dialog,
  Button,
  Divider,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

import { ProductContext } from "../../products/product/Product";
import SubscriptionService from "../../../service/SubscriptionService";
import AlertService from "legacy-components/alert/AlertService";
import Typography from "legacy-components/typography/Typography";
//import { useDialogProps } from "components/dialog/Dialog";
import Dialog, { useDialogProps } from "legacy-components/dialog/Dialog";

export interface UnsubscriberProps {
  productName: string;
  subscriptionId: string;
  applicationName: string;
}

export const Unsubscriber: React.FC<UnsubscriberProps> = ({
  productName,
  subscriptionId,
  applicationName,
}) => {
  const { open, toggle } = useDialogProps();
  const productContext = useContext(ProductContext);

  const removeSubscription = useCallback(() => {
    SubscriptionService.deleteSubscription(productName, subscriptionId)
      .toPromise()
      .then(
        (output) => {
          AlertService.success({
            message: `Subscription is removed for ${applicationName} Application`,
            isAutoHide: true,
            id: "UNSUBSCRIBER",
          });
          toggle();
          setTimeout(() => {
            productContext?.refresh();
          }, 1000);
        },
        (error) => {
          AlertService.error({
            message: "Failed to revoke Subscription ",
            isAutoHide: true,
            id: "UNSUBSCRIBER",
          });
          toggle();
        }
      );
  }, [productContext, subscriptionId, toggle, applicationName]);

  return (
    <>
      <Tooltip title={"Delete Subscription"}>
        <span>
          <IconButton onClick={toggle} size="small" data-testid={`${applicationName}-delete-btn`}>
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={open} title="Remove Subscription" onClose={toggle}>
      <Divider></Divider>
        <>
          <DialogContent>
            <Typography type="body">
              Please confirm the subscription removal for this Application
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={toggle}>
              Skip
            </Button>
            <Button onClick={removeSubscription} color="primary" variant="contained"> Confirm </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default Unsubscriber;
