
import * as actions from "../actions/actions.tsx";
import { updateObject } from "../../utils";

/**
 * @class teamManager
 * @description Redirects user actions
 * @param {Object} action - Action from action creator
 * @param {Object} state - Default application state
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action = {}) => {
     switch (action.type) {
          case actions.storeTeamContext: return storeTeamContext(state, action);
          case actions.storeTeamDetails: return storeTeamDetails(state, action);
          case actions.clearTeamDetails: return clearTeamDetails(state, action);
          default: return state;
     }
};

/**
 * @function clearTeamDetails
 * @description To clear up user details store by OIDC
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const clearTeamDetails = (state, action) => {
     return { team: {} };
}

/**
 * @function storeTeamContext
 * @description To store user team name
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const storeTeamContext = (state, action) => {
     return updateObject(
          state, {
          currentTeam: action.payload
     }
     );
     //return { ...state, ...action.payload };
}

/**
 * @function storeTeamDetails
 * @description To store user team name
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const storeTeamDetails = (state, action) => {
     return updateObject(
          state, {
          teams: action.payload
     }
     );
     // return { ...state, ...action.payload };
}

const INITIAL_STATE = {
};
