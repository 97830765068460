import React, { FC, PropsWithRef, forwardRef, Ref, useState, useCallback } from "react";
import { GridViewAgGridContainer, GridViewFrameworkComponentsType, useGenericGridViewElements, GridViewApprovalStatusBadgeCellRenderer } from "legacy-components/grid-view/GridView";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { ColDef } from "ag-grid-community";
import { useIntl, FormattedMessage } from "react-intl";
import ApprovalService from "../ApprovalService";
import { useHistory } from "react-router-dom";


export interface ApprovalSubscriptionGridRef {
    refreshStream: () => void
}
interface ApprovalSubscriptionGridProps {
    ref: Ref<ApprovalSubscriptionGridRef | undefined>,
    requestType: string
}

const ApprovalSubscriptionGrid: FC<PropsWithRef<ApprovalSubscriptionGridProps>> = forwardRef(({ requestType }, ref) => {
    const intl = useIntl();
    const noDataMessage = <FormattedMessage defaultMessage="No Subscription Approval Requests" />;
    const { onGridReady, frameworkComponents, gridViewOverlayPropRef, getRowNodeId } = useGenericGridViewElements({
        resolverFn: () => ApprovalService.forApproval("SUBSCRIPTION", requestType),
        noDataMessage
    });

    const [columnDefs] = useState<ColDef[]>(() => {
        return [{
            field: "resource.verificationInfo.productName",
            sortable: true,
            filter: "agTextColumnFilter",
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Product"
            }),
            flex: 1
        }, {
            field: "resource.verificationInfo.applicationName",
            sortable: true,
            filter: "agTextColumnFilter",
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Application"
            }),
            flex: 1
        }, {
            field: "action.statusType",
            sortable: true,
            headerName: intl.formatMessage({
                defaultMessage: "Status"
            }),
            flex: 1,
            cellRendererFramework: GridViewApprovalStatusBadgeCellRenderer

        }, {
            field: "requestDate",
            sortable: true,
            headerName: intl.formatMessage({
                defaultMessage: "Date Submitted"
            }),
            flex: 1
        },
        {
            field: "action.reviewedBy.name",
            sortable: true,
            headerName: intl.formatMessage({
                defaultMessage: "Reviewed By"
            }),
            flex: 1
        },
        {
            field: "action.statusDate",
            sortable: true,
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Date Reviewed"
            }),
            flex: 1
        },
        ];
    });
    let history = useHistory();
    const onCellClicked = useCallback((event: any) => {
        event.colDef.field !== 'actions' && history.push(`/home/approvals/subscription/${event.data.resource.name}/${event.data.requestId}/${requestType.toLowerCase()}`);
    }, [history]);
    return (
        <GridViewAgGridContainer>
            <AgGridReact
                columnDefs={columnDefs}
                frameworkComponents={frameworkComponents}
                loadingOverlayComponent={GridViewFrameworkComponentsType.LoadingOverlay}
                noRowsOverlayComponent={GridViewFrameworkComponentsType.NoRowsOverlay}
                noRowsOverlayComponentParams={{ gridViewOverlayPropRef }}
                suppressRowClickSelection={true}
                suppressCellSelection={true}
                suppressRowHoverHighlight={true}
                getRowNodeId={getRowNodeId}
                onGridReady={onGridReady}
                onCellClicked={onCellClicked}
            />
        </GridViewAgGridContainer>
    )
})

export default (ApprovalSubscriptionGrid);
