import React, { FC, useState, useEffect, useRef, useCallback } from 'react'
import { FormattedMessage } from 'react-intl';
import Container from '@material-ui/core/Container';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionForm, { AccordionSummaryTitle, AccordionContent } from 'legacy-components/form/components/accordion-form/AccordionForm';
import ApprovalService from "../ApprovalService";
import { Typography, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { Approval } from '../model/approval.model';
import { useAsyncAction, AsyncLoader } from 'legacy-components/form/action/AsyncAction';
import GenericPageHeader from 'legacy-components/page-headers/GenericPageHeader';
import { useResourceViewStyles } from 'legacy-components/resource-view/ResourceView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormViewInliner from 'legacy-components/form/container/FormViewInliner';
import NAWrap from 'legacy-components/form/action/NAWrap';
import { withRouter, RouteComponentProps } from 'react-router';
import { StatusBadge } from 'legacy-components/badges/StatusBadge';
import ApprovalDecisionForm from './forms/ApprovalDecisionForm';
import ApplicationUsecaseView from '../subscription/view/ApplicationUsecaseView';
import PersonIcon from '@material-ui/icons/Person';
import ActionInliner from 'legacy-components/form/action/ActionInliner';
import ApplicationUsecaseForm from 'pages/home/approval/subscription/forms/ApplicationUsecaseForm';
import DelegateDialog, { DelegateDialogRef } from '../subscription/dialog/DelegateDialog';
import { Alert } from '@material-ui/lab';

interface SubscriptionApprovalReviewProps {
    approval?: Approval,
    subscription?: string,
    onSuccess?: () => void
}

interface RouterPathProps {
    subscription: string,
    request: string,
    requestType: string
}
const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        accordionSummary: {
            padding: '10px 15px',
            margin: 0,
            "&.Mui-expanded": {
                margin: " 0 !important",
                padding: "0 15px"
            },
            align: "center"
        },
        accordionDetails: {
            padding: 0
        },
        submissionDetails: {
            padding: spacing(0, 0, 3, 0)
        },
        root: {
            //padding: spacing(2),
            textAlign: "center",
            width: "100%"
        },
        title: {
            //marginBottom: "150px"
        },
        heading: {
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        container: {
            marginBottom: '10px'
        },
        container1: {
            marginBottom: '10px',
            width: "75%"
        },
        secondaryHeading: {
            float: "right"
        },
        round: {
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            background: "#00437B",
            fontSize: "15px",
            color: "#fff",
            textAlign: "center",
            lineHeight: "50px",
            margin: "20px 0",
        }
    }));
const SubscriptionSubscriptionApprovalReview: FC<RouteComponentProps<RouterPathProps> & SubscriptionApprovalReviewProps> = ({ match }) => {
    const subscription = match.params.subscription;
    const requestId = match.params.request;
    const requestType = match.params.requestType;
    const [isFormCompleted, setIsFormCompleted] = useState<string>();
    const [] = useState(false);
    const [isSubmitted] = useState(false);

    const [, setApproval] = useState<Approval>();
    const onSuccessForm = () => {
        setIsFormCompleted(Math.random().toString());
    };
    const getApprovalVerificationDetails = (approval: Approval) => {

        setApproval(approval);
        return approval;
    }
    const [response, { subscribe }] = useAsyncAction(() => ApprovalService.forApprovalByRequest(requestId, requestType.toUpperCase()),
        {
            mapFn: getApprovalVerificationDetails
        });
    useEffect(() => {
        const unsubscribe = subscribe()

        return () => unsubscribe();
    }, [subscribe, isSubmitted, isFormCompleted]);

    const classes = useStyles();

    const styles = useResourceViewStyles();
    const StatusSummary = (
        <AccordionSummaryTitle>
            <FormattedMessage defaultMessage="Submission Overview" />
        </AccordionSummaryTitle>
    );

    const ReviewDetails = (
        <AccordionSummaryTitle>
            <FormattedMessage defaultMessage="Submission Details" />
        </AccordionSummaryTitle>
    )

    const ReviewerDetails = (
        <AccordionSummaryTitle>
            <FormattedMessage defaultMessage="Reviewers" />
        </AccordionSummaryTitle>
    )

    const Decision = (
        <AccordionSummaryTitle>
            <FormattedMessage defaultMessage="Decision" />
        </AccordionSummaryTitle>
    )

    const formatDate = (date: any) => {
        const d = new Date(date);
        let currentHours: string = d.getHours().toString();
        currentHours = ("0" + currentHours).slice(-2);

        let currentMins: string = d.getMinutes().toString();
        currentMins = ("0" + currentMins).slice(-2);
        let formatted = d.getDate().toString().concat("/").concat(d.getMonth().toString().concat("/").concat(d.getFullYear().toString().concat(" ").concat(currentHours.concat(":").concat(currentMins))));
        return formatted;
    }

    const confirmDialogRef = useRef<DelegateDialogRef>();


    const onSuccess = useCallback(() => {
        confirmDialogRef?.current?.close();
        setIsFormCompleted(Math.random().toString());
    }, []);

    return (
        <>{ response?.status == "error" && (<Alert severity='error'>The following approval review is restricted to the following user for the selected team </Alert>)
    }
        <AsyncLoader state={response} loader={<> </>}>
            <GenericPageHeader title={response.value?.resource?.verificationInfo.productName} header={<FormattedMessage defaultMessage="Review" />} controls={<></>} />
            <Container maxWidth="md">
                <AccordionForm id="approval1">

                    <AccordionForm.type.Section id="approval-detail" summary={StatusSummary}>
                        <div className={styles.section}>
                            <AccordionContent>
                                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Status" />}>
                                    <StatusBadge align="right" status={{
                                        kind: "access",
                                        key: response.value?.action?.statusType
                                    }} />
                                </FormViewInliner>
                                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Submitted By" />}>
                                    <NAWrap value={response.value?.requestedBy} showMessage={true}>
                                        <ListItem key="requestedBy">
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PersonIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<span>{response.value?.requestedBy?.name}</span>}
                                                secondary={response.value?.requestedBy?.emailAddress ?? null}
                                            />
                                        </ListItem>
                                    </NAWrap>
                                </FormViewInliner>
                                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Date Submitted" />}>
                                    <Typography variant="body1" className="no-overflow">
                                        <NAWrap value={response.value?.requestDate} showMessage={true}>
                                            {formatDate(response.value?.requestDate)}
                                        </NAWrap>
                                    </Typography>
                                </FormViewInliner>
                                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Reviewed By" />}>
                                    <Typography variant="body1" className="no-overflow">
                                        <NAWrap value={response.value?.action?.reviewedBy} showMessage={true}>
                                            <ListItem key="reviewedBy">
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <PersonIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={<span>{response.value?.action?.reviewedBy?.name}</span>}
                                                    secondary={response.value?.action?.reviewedBy?.emailAddress ?? null}
                                                />
                                            </ListItem>
                                        </NAWrap>
                                    </Typography>
                                </FormViewInliner>
                                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Date Reviewed" />}>
                                    <Typography variant="body1" className="no-overflow">
                                        <NAWrap value={response.value?.action?.statusDate} showMessage={true}>
                                            {formatDate(response.value?.action?.statusDate)}
                                        </NAWrap>
                                    </Typography>
                                </FormViewInliner>
                            </AccordionContent>
                        </div>
                    </AccordionForm.type.Section>
                </AccordionForm>

                <AccordionForm id="submission-details">
                    <AccordionForm.type.Section id="approval-summary" summary={ReviewDetails}>

                        <div className={styles.section}>

                            <AccordionSummary
                                className={classes.accordionSummary}
                            >
                                <AccordionSummaryTitle>
                                    <Typography className={classes.heading} variant="h6" >
                                        Application & Use Case
                                    </Typography>
                                </AccordionSummaryTitle>
                                {response.value?.resource?.feedback?.created &&
                                    <Typography className={classes.heading} variant="subtitle2" >
                                        Last reviewed {formatDate(response.value?.resource?.feedback?.created)}
                                    </Typography>}

                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetails}>
                                <AccordionContent>
                                    <ApplicationUsecaseView subscriptionVerificationInfo={response.value?.resource?.verificationInfo} />
                                    {((response?.value?.canReview || response.value?.action?.reviewedBy) && <ApplicationUsecaseForm apiFeedback={{
                                        requestId: response.value?.requestId ? response.value?.requestId : "",
                                        feedback: response.value?.resource?.feedback?.feedback
                                    }} onSuccess={onSuccessForm} canReview={response?.value?.canReview}
                                        approvalDecisionInfo={response.value?.action} />)}
                                </AccordionContent>
                            </AccordionDetails>

                        </div>
                    </AccordionForm.type.Section>
                </AccordionForm>
                <AccordionForm id="approval1">

                    <AccordionForm.type.Section id="approval-reviewer" summary={ReviewerDetails}>
                        <div className={styles.section}>
                            <AccordionContent

                                actions={(response.value?.canDelegate && response.value?.action?.statusType === "PENDING_APPROVAL" ? <ActionInliner padding="inset-top">
                                    <DelegateDialog ref={confirmDialogRef} requestId={response.value?.requestId} subscription={subscription} product={response.value?.resource?.verificationInfo.productName}
                                        application={response.value?.resource?.verificationInfo.applicationName} onSuccess={onSuccess} />
                                </ActionInliner> : <></>)}>
                                {response.value?.reviewers.map((item, index) => (
                                    <FormViewInliner variant="list" key={index} gutter="gutter-bottom"
                                        controls={response.value?.canDelegate ? item.delegatedBy &&
                                            <span>
                                                delegated this submission on {formatDate(item.created)}.
                                            </span> : <></>
                                        }
                                        title={<></>}
                                    >
                                        <ListItem key={index}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PersonIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<span>{item.reviewer.name}</span>}
                                                secondary={item.reviewer.emailAddress ?? null}
                                            />
                                        </ListItem>
                                    </FormViewInliner>
                                ))}
                            </AccordionContent>
                        </div>
                    </AccordionForm.type.Section>
                </AccordionForm>
                {((response?.value?.canReview || response.value?.action?.reviewedBy) && <AccordionForm id="approval2">
                    <AccordionForm.type.Section id="approval-decision" summary={Decision}>
                        <ApprovalDecisionForm subscription={subscription} product={response.value?.resource?.verificationInfo.productName}
                            application={response.value?.resource?.verificationInfo.applicationName}
                            canReview={response?.value?.canReview} approvalDecisionInfo={{
                                statusType: response.value?.action?.reviewedBy ? response.value?.action?.statusType : "",
                                reason: response.value?.action?.reason
                            }} onSuccess={onSuccessForm} />
                    </AccordionForm.type.Section>
                </AccordionForm>)}

            </Container>
        </AsyncLoader >
        </>
    );
}

SubscriptionSubscriptionApprovalReview.propTypes = {
}

export default withRouter(SubscriptionSubscriptionApprovalReview);