import React, {FC, useCallback, useContext, useState} from "react";
import { Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { ValidatorBuilderContext } from "legacy-components/form/validator/Validator";
import FormTextInput from "legacy-components/form/input/FormTextInput";
import FormInputContainer from "legacy-components/form/container/FormInputContainer";
import FormContainer, { FormContainerOptions, FormContainerSubmitHandler } from "legacy-components/form/container/FormContainer";
import { Field, FormRenderProps } from "react-final-form";
import ActionInliner from "legacy-components/form/action/ActionInliner";
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";
import { FORM_ERROR } from "final-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toWords } from "number-to-words";
const useStyles = makeStyles(({ spacing, palette }) =>
    createStyles({
        root: {
            padding: '20px 0'
        },
        subTitle: {
            padding: '0 30px',
            paddingBottom: 0,
            marginBottom: '-20px'
        },
        time: {
            width: '48%'
        }
    }));

export interface SpikeArrestFormModel {
    spikeArrestLimit?: number,
    spikeArrestTimeunit?: string,
    spikeArrestEnabled?: boolean
    __spikeArrestInfo?: {
        id: string,
        spikeArrestLimit: number,
        spikeArrestTimeunit: string,
        spikeArrestEnabled: boolean
    }
}
export interface SubscriptionQuotaFormModel {
    subscriptionQuotaLimit?: number,
    subscriptionQuotaInterval?: number,
    subscriptionQuotaTimeunit?: string,
    subscriptionQuotaEnabled?: boolean
    __subscriptionQuotaInfo?: {
        id: string,
        subscriptionQuotaLimit: number,
        subscriptionQuotaInterval: number
        subscriptionQuotaTimeunit: string
        subscriptionQuotaEnabled: boolean
    }
}

const ThrottlingForm: FC<any> = (props) => {
    const validator = useContext(ValidatorBuilderContext);
    const [spikeArrestDataFields] = useState(["__spikeArrestInfo"])
    const [subscriptionQuotaDataFields] = useState(["__subscriptionQuotaInfo"]);
    const history = useHistory();
    let proxyDetails = useSelector((state: any) => state.proxyManager.proxy);

    const classes = useStyles();


    const onSpikeArrestEnabled: FormContainerSubmitHandler<SpikeArrestFormModel> = ({ stepperOptions }) => {
        return (data, form) => {
            return new Promise((resolve) => {
                const formState = form.getState();

                if (formState.pristine) {
                    resolve(null);
                } else {
                    updateThrottling(data, resolve);
                }
            })
        }
    }

    const onSpikeArrestDisabled = () => {
        updateThrottling({ spikeArrestEnabled: false });
    }
    const onSubscriptionQuotaEnabled: FormContainerSubmitHandler<SubscriptionQuotaFormModel> = ({ stepperOptions }) => {
        return (data, form) => {
            return new Promise((resolve) => {
                const formState = form.getState();

                if (formState.pristine) {
                    resolve(null);
                } else {
                    updateThrottling(data, resolve);
                }
            })
        }
    }
    const onSubscriptionQuotaDisabled = () => {
        updateThrottling({ subscriptionQuotaEnabled: false });
    }
    const updateThrottling = (data: any, resolve?: Function) => {
        // Spike Arrest Details
        let spikeArrest: any = {};
        spikeArrest['spikeArrestLimit'] = data.spikeArrestLimit ?? props.throttling?.spikeArrestLimit;
        spikeArrest['spikeArrestTimeunit'] = data.spikeArrestTimeunit ?? props.throttling?.spikeArrestTimeunit;
        spikeArrest['spikeArrestEnabled'] =
            ((data.spikeArrestEnabled !== undefined) ?
                data.spikeArrestEnabled :
                ((data.spikeArrestLimit || data.spikeArrestTimeunit) ?
                    true : (props.throttling?.spikeArrestEnabled ?? false)));
        let subscriptionQuota: any = {};
        // Subscription Quota
        subscriptionQuota['subscriptionQuotaLimit'] = data.subscriptionQuotaLimit ?? props.throttling?.subscriptionQuotaLimit;
        subscriptionQuota['subscriptionQuotaInterval'] = data.subscriptionQuotaInterval ?? props.throttling?.subscriptionQuotaInterval;
        subscriptionQuota['subscriptionQuotaTimeunit'] = data.subscriptionQuotaTimeunit ?? props.throttling?.subscriptionQuotaTimeunit;
        subscriptionQuota['subscriptionQuotaEnabled'] =
            ((data.subscriptionQuotaEnabled !== undefined) ?
                data.subscriptionQuotaEnabled :
                ((data.subscriptionQuotaLimit || data.subscriptionQuotaInterval || data.subscriptionQuotaTimeunit) ?
                    true : (props.throttling?.subscriptionQuotaEnabled ?? false)));
        let apiThrottling: any = { spikeArrest, subscriptionQuota };

        UnifiedAPIService.post(
            UnifiedAPI.parseRequestURL(UnifiedAPI.api_throttling, {
                apiName: proxyDetails?.apiName,
                environmentName: props.env
            }),
            apiThrottling
        ).then(() => {
            resolve && resolve();
            props.onUpdateSuccess && props.onUpdateSuccess();
            //history.go(0);
        }, error => {
            resolve && resolve({
                [FORM_ERROR]: error.response?.data?.message ?? "Unknown error occured"
            });
        });
    }
    const intl = useIntl();


    const SpikeForm = (formRenderProps: FormRenderProps<SpikeArrestFormModel, {}>, containerOptions: FormContainerOptions) => {
        const formState = formRenderProps.form.getState();
        const timeUnits: Array<any> = [
            {
                key: "SECOND",
                value: intl.formatMessage({
                    defaultMessage: "Second"
                })
            },
            {
                key: "MINUTE",
                value: intl.formatMessage({
                    defaultMessage: "Minute"
                })
            }
        ];

        return (
            <>
                <Typography variant="subtitle1" className={classes.subTitle} children={<FormattedMessage defaultMessage="Spike Arrest" />} />
                <FormInputContainer inline={true} padding="inset">
                    <Field
                        name="spikeArrestLimit"
                        type="number"
                        disabled={!!formState.values.__spikeArrestInfo?.id}
                        defaultValue={props.throttling?.spikeArrestLimit}
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Limit"
                        })}
                        placeholder={intl.formatMessage({
                            defaultMessage: "Spike Arrest Limit"
                        })}
                        helperText={intl.formatMessage({
                            defaultMessage: "Number of requests to be allowed"
                        })}
                        validate={validator.from({
                            required: true,
                            min: 1,
                            max: (formState?.values?.spikeArrestTimeunit === "MINUTE" ? 180000 : 3000),
                            pattern: ({ Numeric }) => Numeric
                        })}
                    />
                    <span>&nbsp;&nbsp;/</span>
                    <Field
                        name="spikeArrestTimeunit"
                        type="text"
                        component={FormTextInput}
                        className={classes.time}
                        defaultValue={props.throttling?.spikeArrestTimeunit ?? timeUnits[0].key}
                        label={intl.formatMessage({
                            defaultMessage: "Unit Time"
                        })}

                        options={timeUnits}
                        validate={validator.from({
                            required: true
                        })}
                    >
                        {({ input, meta }) => {
                            return (
                              <FormTextInput input={input} meta={meta} options={timeUnits}
                                             onChange={(event) => {
                                                 input.onChange(event);
                                                 setTimeout(() => {
                                                     const spikeArrestLimitValue = formRenderProps.form.getFieldState("spikeArrestLimit")?.value;
                                                     formRenderProps.form.getFieldState("spikeArrestLimit")?.focus();
                                                     formRenderProps.form.getFieldState("spikeArrestLimit")?.change(0);
                                                     formRenderProps.form.getFieldState("spikeArrestLimit")?.change(spikeArrestLimitValue);
                                                     formRenderProps.form.getFieldState("spikeArrestLimit")?.blur();
                                                 }, 100);
                                                 return true;
                                             }}
                              />
                            )
                        }}
                    </Field>
                </FormInputContainer>
                <ActionInliner padding="inset">
                    {/*{props.throttling?.spikeArrestEnabled &&
                        <>
                            <Button disabled={true} onClick={onSpikeArrestDisabled}
                                type="button" variant="outlined" disableElevation>
                                <FormattedMessage defaultMessage="Disable Spike Arrest" />
                            </Button>
                        </>
                    }*/}
                    <Button disabled={formRenderProps.submitting} type="submit"
                        variant="contained" color="primary" disableElevation>
                        {props.throttling?.spikeArrestEnabled ? <FormattedMessage defaultMessage="Update" /> : <FormattedMessage defaultMessage="Enable Spike Arrest" />}
                    </Button>
                </ActionInliner>
            </>);
    }
    const SubscriptionForm = (formRenderProps: FormRenderProps<SubscriptionQuotaFormModel, {}>, containerOptions: FormContainerOptions) => {
        const formState = formRenderProps.form.getState();
        const timeUnits: Array<any> = [
            {
                key: "MINUTE",
                value: intl.formatMessage({
                    defaultMessage: "Minute"
                })
            }, {
                key: "HOUR",
                value: intl.formatMessage({
                    defaultMessage: "Hour"
                })
            }, {
                key: "DAY",
                value: intl.formatMessage({
                    defaultMessage: "Day"
                })
            }, {
                key: "WEEK",
                value: intl.formatMessage({
                    defaultMessage: "Week"
                })
            }, {
                key: "MONTH",
                value: intl.formatMessage({
                    defaultMessage: "Month"
                })
            }
        ];

        let subscriptionQuotaMaxLimit: () => (number);
        subscriptionQuotaMaxLimit = () => {
            switch (formState?.values?.subscriptionQuotaTimeunit) {
                case "MONTH":
                    return 2000000000;
                default:
                    return 20000000;
            }
        };

        const subscriptionQuotaMaxLimitValidator = useCallback(() => (value: number) => {
            const maxVal = subscriptionQuotaMaxLimit();
            return value && value > maxVal ? `Limit ${toWords(maxVal)}. ` : undefined
        }, [subscriptionQuotaMaxLimit]);

        return (
            <>
                <Typography variant="subtitle1" className={classes.subTitle} children={<FormattedMessage defaultMessage="Subscription Quota" />} />
                <FormInputContainer inline={true} padding="inset">
                    <Field
                        name="subscriptionQuotaLimit"
                        type="number"
                        disabled={!!formState.values.__subscriptionQuotaInfo?.id}
                        defaultValue={(props.throttling?.subscriptionQuotaLimit > 1) ? props.throttling?.subscriptionQuotaLimit : ""}
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "Quota Limit"
                        })}
                        placeholder={intl.formatMessage({
                            defaultMessage: "Quota Limit"
                        })}
                        helperText={intl.formatMessage({
                            defaultMessage: "Allowed subscription limit"
                        })}
                        validate={validator.from({
                            required: true,
                            min: 1,
                            custom: subscriptionQuotaMaxLimitValidator,
                            pattern: ({ Numeric }) => Numeric
                        })}
                    />
                    <span>&nbsp;&nbsp;/</span>
                    <Field
                        name="subscriptionQuotaInterval"
                        type="number"
                        disabled={!!formState.values.__subscriptionQuotaInfo?.id}
                        defaultValue={props.throttling?.subscriptionQuotaInterval > 0 ? props.throttling?.subscriptionQuotaInterval : ""}
                        component={FormTextInput}
                        label={intl.formatMessage({
                            defaultMessage: "interval"
                        })}
                        placeholder={intl.formatMessage({
                            defaultMessage: "interval"
                        })}
                        validate={validator.from({
                            required: true,
                            min: 1,
                            pattern: ({ Numeric }) => Numeric
                        })}
                    />
                    <Field
                        name="subscriptionQuotaTimeunit"
                        type="text"
                        component={FormTextInput}
                        defaultValue={props.throttling?.subscriptionQuotaTimeunit ?? timeUnits[0].key}
                        label={intl.formatMessage({
                            defaultMessage: "Unit Time"
                        })}

                        options={timeUnits}
                        validate={validator.from({
                            required: true,
                            min: 1
                        })}
                    >
                        {({ input, meta }) => {
                            return (
                              <FormTextInput input={input} meta={meta} options={timeUnits}
                                             onChange={(event) => {
                                                 input.onChange(event);
                                                 setTimeout(() => {
                                                     const spikeArrestLimitValue = formRenderProps.form.getFieldState("subscriptionQuotaLimit")?.value;
                                                     formRenderProps.form.getFieldState("subscriptionQuotaLimit")?.focus();
                                                     formRenderProps.form.getFieldState("subscriptionQuotaLimit")?.change(0);
                                                     formRenderProps.form.getFieldState("subscriptionQuotaLimit")?.change(spikeArrestLimitValue);
                                                     formRenderProps.form.getFieldState("subscriptionQuotaLimit")?.blur();
                                                 }, 100);
                                                 return true;
                                             }}
                              />
                            )
                        }}
                    </Field>
                </FormInputContainer>
                <ActionInliner padding="inset">
                    {/*{props.throttling?.subscriptionQuotaEnabled &&
                        <>
                            <Button disabled={true} onClick={onSubscriptionQuotaDisabled}
                                type="button" variant="outlined" disableElevation>
                                <FormattedMessage defaultMessage="Disable Subscription Quota" />
                            </Button>
                        </>
                    }*/}
                    <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation>
                        {props.throttling?.subscriptionQuotaEnabled ? <FormattedMessage defaultMessage="Update" /> : <FormattedMessage defaultMessage="Enable Subscription Quota" />}
                    </Button>

                </ActionInliner>
            </>);
    }

    return (
        <div className={classes.root}>
            <FormContainer stepperId="spikeArrest" dataFields={spikeArrestDataFields} FormProps={{ onSubmit: onSpikeArrestEnabled }} children={SpikeForm} />
            <FormContainer stepperId="subscriptionQuota" dataFields={subscriptionQuotaDataFields} FormProps={{ onSubmit: onSubscriptionQuotaEnabled }} children={SubscriptionForm} />
        </div>
    );
}

export default ThrottlingForm;