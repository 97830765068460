import { Box } from "@material-ui/core";
import React, { ReactElement } from "react";

export const ErrorMessage = (error: any) => {
    console.log(resolveAPIErrorMessage(error));
    return (
        <Box m={1} p={3} 
            width="100%" minHeight={"200px"} 
                border="1px solid #000" display={"flex"} 
                textAlign={"center"}
                justifyContent={"center"}
                alignItems={"center"}>
            {resolveAPIErrorMessage(error)}
        </Box>
    );
}

  

interface APIErrorType1 {
    response: {
      data: {
        message?: string;
        code?: string;
      };
    };
  }
  interface APIErrorType2 {
    data: {
      message?: string;
      code?: string;
    };
  }
  interface APIErrorType3 {
    message?: string;
    code?: string;
  }
  export type APIErrorType =
    | APIErrorType1
    | APIErrorType2
    | APIErrorType3
    | unknown
    | any;
  
export const resolveAPIErrorMessage = (
    error: APIErrorType,
    customMessage: ReactElement | string = "Apologies! An error has occurred, Please try loading this page after some time."
  ) => {
    if(!error) {
        return customMessage;
    }
    if (error?.response?.data?.message) {
      return error?.response?.data?.message;
    }
    if (error?.message) {
      if (error?.message !== 'Network Error') {
        return error?.message;
      }
    }
    if (error?.data?.message) {
      return error?.data?.message;
    }
    return customMessage;
  };