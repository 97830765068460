import React, { useEffect, useState } from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import PropTypes from "prop-types";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { FormattedMessage } from "react-intl";
import GenericPageHeader from "legacy-components/page-headers/GenericPageHeader";
import InlineIconFormatter from "legacy-components/formatters/InlineIconFormatter";
import { Button, Link, Typography, Accordion, ButtonGroup, Tooltip} from "@material-ui/core";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import { useSelector } from "react-redux";
import ProxyEnvironment from "./proxyEnvironment";
import Helper from "pages/home/helper/Helper";
import EnvironmentHelper from "pages/home/helper/EnvironmentHelper";
import ResourceAuthorizer from "./authorizer/ResourceAuthorizer";
import ProxyVerification from "./verification/ProxyVerification";
import {
  useResourceAbility,
  useFeatureAbility,
} from "libs/security/authorization/Permission";
import { Feature } from "libs/security/authorization/FeatureAbility";
import ProxyService from "libs/resources/proxy/ProxyService";
import {
  AsyncLoader,
  useAsyncAction,
} from "legacy-components/form/action/AsyncAction";
import {
  AccordionSummaryTitle,
  AccordionContent,
} from "legacy-components/form/components/accordion-form/AccordionForm";
import FormViewInliner from "legacy-components/form/container/FormViewInliner";
import NAWrap from "legacy-components/form/action/NAWrap";
import { StatusBadge } from "legacy-components/badges/StatusBadge";
import CopyToClipboard from "legacy-components/form/action/CopyToClipboard";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      marginBottom: "15px",
    },
    tabs: {
      marginBottom: "30px",
    },
    container: {
      marginBottom: "10px",
    },
    icon: {
      color: palette.text.primary,
    },
  })
);

ProxySingleEnvironments.propTypes = {
  teamProxies: PropTypes.any,
  sharedProxies: PropTypes.any,
  header: PropTypes.any,
};

export default function ProxySingleEnvironments(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [isShowEnvironmentHelper, toggleEnvironmentHelper] = useState(false);
  const [canShowResourceAuthorizer, setShowResourceAuthorizer] = React.useState(
    {}
  );
  let gateways = useSelector((state) => state?.common?.gateway);
  const [, setApi] = useState();

  const [isFormCompleted, setIsFormCompleted] = useState();

  const onSuccess = () => {
    setIsFormCompleted(Math.random().toString());
  };

  const getApiDetails = (api) => {
    setApi(api);
    return api;
  };
  const [response, { subscribe }] = useAsyncAction(
    () => ProxyService.forApi(props.proxy?.apiName),
    {
      mapFn: getApiDetails,
    }
  );
  useEffect(() => {
    const unsubscribe = subscribe();

    return () => unsubscribe();
  }, [subscribe, isFormCompleted]);

  const [can, state] = useResourceAbility({
    resource: ({ Proxy }) => Proxy.deploy.environment["trimble-pre-prod"],
  });

  const [hasInvite] = useResourceAbility({
    resource: ({ TeamMembers }) => TeamMembers.invite,
  });

  useEffect(() => {
    gateways &&
      gateways.forEach((gateway) => {
        canShowResourceAuthorizer[gateway.name] =
          response.value?.apiDeployments.filter((deployment) => {
            return (
              deployment?.organisation?.toLowerCase() ===
                gateway?.name?.toLowerCase() &&
              deployment.deployStatus === "DEPLOY_COMPLETED"
            );
          }).length > 0;
      });
    setShowResourceAuthorizer({ ...canShowResourceAuthorizer });
  }, [response.value, gateways, hasInvite?.create()]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [canVerify, hasVerification] = useResourceAbility({
    resource: ({ Proxy }) => Proxy.verification.getStarted,
  });

  const hasFeature = useFeatureAbility({
    id: Feature.ApiVerification,
  });

  const CopyAPIName = (
    <CopyToClipboard size="small" edge="end" text={props.proxy?.apiName} />
  );

  return (
    response.value && (
      <AsyncLoader state={response} loader={<> </>}>
        <div className={classes.root}>
          {gateways && (
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.tabs}
            >
              {gateways.map((gateway) => (
                <Tab
                  label={
                    gateway?.name?.toLowerCase() === "trimble-pre-prod"
                      ? "Pre Prod"
                      : "Prod"
                  }
                  key={gateway.name}
                />
              ))}

              {(hasVerification.loaded || hasVerification.intermediate) &&
                canVerify.create() &&
                hasFeature && (
                  <Tab label="Verification" key="proxy-verification" />
                )}
            </Tabs>
          )}
          {gateways &&
            response.value &&
            (state.loaded || state.intermediate) &&
            can.create() && (
              <div>
                {gateways &&
                  gateways.map((gateway, index) => (
                    <TabPanel
                      value={value}
                      index={index}
                      dir={theme.direction}
                      key={index}
                    >
                      <Container maxWidth="md" className={classes.container}>
                        <GenericPageHeader
                          title={
                            <Typography
                              variant="subtitle1"
                              className={classes.title}
                            >
                              <InlineIconFormatter
                                icon={
                                  <HelpOutlineOutlinedIcon
                                    onClick={() => {
                                      toggleEnvironmentHelper(true);
                                    }}
                                  />
                                }
                              >
                                <FormattedMessage defaultMessage="Environment" />
                              </InlineIconFormatter>
                            </Typography>
                          }
                        />
                        <Helper
                          open={isShowEnvironmentHelper}
                          handleClose={() => {
                            toggleEnvironmentHelper(false);
                          }}
                          children={<EnvironmentHelper />}
                        ></Helper>
                        <div>
                          {response &&
                            response.value &&
                            gateway.environments.map((environment, index) => {
                              let deployments =
                                response.value?.apiDeployments.filter(
                                  (apiDeployment) =>
                                    apiDeployment.environmentName ===
                                    environment.environmentName
                                );
                              let deployment = deployments.length > 1 ? deployments.filter((apiDeployment) => 
                              apiDeployment.provider === 'azure')?.[0] : deployments.length === 1 ? deployments[0] : null;
                              return (
                                response &&
                                response.value && (
                                  <ProxyEnvironment
                                    onSuccess={onSuccess}
                                    isShared={response.value?.isShared}
                                    env={environment}
                                    deployment={deployment}
                                    key={index}
                                    onUpdate={props.onUpdate}
                                  />
                                )
                              );
                            })}
                        </div>
                      </Container>
                      <Container maxWidth="md" className={classes.container}>
                        <div>
                          {
                            <ResourceAuthorizer
                              onSuccess={onSuccess}
                              apiName={response.value?.apiName}
                              gatewayName={gateway?.name?.toLowerCase()}
                              resourceServer={
                                response.value?.resourceServer.filter(
                                  (resourceServer) =>
                                    resourceServer.gateway_name === gateway.name
                                )[0] ?? null
                              }
                            />
                          }
                        </div>
                      </Container>
                    </TabPanel>
                  ))}
                {(hasVerification.loaded || hasVerification.intermediate) &&
                  canVerify.create() &&
                  hasFeature && (
                    <TabPanel value={value} index={1}>
                      <ProxyVerification proxy={response.value?.apiName} />
                    </TabPanel>
                  )}
                <TabPanel value={value} index={0}>
                  <Container maxWidth="md" className={classes.container}>
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        className={classes.accordionSummary}
                      >
                        <AccordionSummaryTitle>Details</AccordionSummaryTitle>
                      </AccordionSummary>

                      <AccordionDetails className={classes.accordionDetails}>
                        <AccordionContent>
                          <FormViewInliner
                            gutter="gutter-bottom"
                            title={
                              <FormattedMessage defaultMessage="API Proxy" />
                            }
                            controls={CopyAPIName}
                          >
                            <Typography variant="body1" className="no-overflow">
                              <NAWrap
                                value={response.value?.apiName}
                                showMessage={true}
                              >
                                {response.value?.apiName}
                              </NAWrap>
                            </Typography>
                          </FormViewInliner>
                          <FormViewInliner
                            gutter="gutter-bottom"
                            title={
                              <FormattedMessage defaultMessage="Version" />
                            }
                          >
                            <Typography variant="body1" className="no-overflow">
                              <NAWrap
                                value={response.value?.version}
                                showMessage={true}
                              >
                                {response.value?.version}
                              </NAWrap>
                            </Typography>
                          </FormViewInliner>
                          <FormViewInliner
                            gutter="gutter-bottom"
                            title={
                              <FormattedMessage defaultMessage="Description" />
                            }
                          >
                            <Typography variant="body1" className="no-overflow">
                              <NAWrap
                                value={response.value?.description}
                                showMessage={true}
                              >
                                {response.value?.description}
                              </NAWrap>
                            </Typography>
                          </FormViewInliner>
                          {hasInvite?.create() && (
                            <>
                              <FormViewInliner
                                gutter="gutter-bottom"
                                title={
                                  <FormattedMessage defaultMessage="Created By" />
                                }
                              >
                                <Typography
                                  variant="body1"
                                  className="no-overflow"
                                >
                                  <NAWrap
                                    value={response.value?.createdBy}
                                    showMessage={true}
                                  >
                                    {response.value?.createdBy}
                                  </NAWrap>
                                </Typography>
                              </FormViewInliner>
                              <FormViewInliner
                                gutter="gutter-bottom"
                                title={
                                  <FormattedMessage defaultMessage="Updated By" />
                                }
                              >
                                <Typography
                                  variant="body1"
                                  className="no-overflow"
                                >
                                  <NAWrap
                                    value={response.value?.updatedBy}
                                    showMessage={true}
                                  >
                                    {response.value?.updatedBy}
                                  </NAWrap>
                                </Typography>
                              </FormViewInliner>
                            </>
                          )}
                          <FormViewInliner
                            gutter="gutter-bottom"
                            title={<FormattedMessage defaultMessage="Status" />}
                          >
                            <Typography variant="body1" className="no-overflow">
                              <StatusBadge
                                align="right"
                                status={{
                                  kind: "access",
                                  key: response.value?.verified
                                    ? "VERIFIED"
                                    : "UNVERIFIED",
                                }}
                              />
                            </Typography>
                          </FormViewInliner>
                        </AccordionContent>
                      </AccordionDetails>
                    </Accordion>
                  </Container>
                </TabPanel>
              </div>
            )}
        </div>
      </AsyncLoader>
    )
  );
}
