import React from 'react';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import { ProductSubscriptionList } from './product-subscription-list/ProductSubscriptionList';
import SubscriptionSummaryPage from './subscription-summary-page/SubscriptionSummaryPage';

const fallBackdiv = <div className="message info">You do not have permission to access the page. Please contact your Team owner.</div>
/**
 * @class HomeRoutes
 * @description For storing internal page redirection inside Home Module
 */
class SubscriptionRoutes extends React.Component<any, any> {

     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {
          return (
               <Switch>
                    <Route path="/home/subscriptions" exact render={() => (<ProductSubscriptionList />)} />
                    <Route path="/home/subscriptions/:subscriptionId" render={() => (<SubscriptionSummaryPage />)} />

               </Switch>
          );
     }
}

export default withRouter(SubscriptionRoutes);