import React, { FC, useCallback, useRef, useContext } from "react";
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTheme, Button, Tabs, Tab, makeStyles, createStyles, Box, ButtonGroup, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ResourceAbility, useResourceAbility } from "libs/security/authorization";
import { FormattedMessage } from "react-intl";
import GenericPageHeader from "../../../legacy-components/page-headers/GenericPageHeader";
import { useGridViewStyles } from "../../../legacy-components/grid-view/GridView";
import CertificateGrid, { CertificateGridRef } from "./CertificateGrid";
import CertificateUploadDialog, { CertificateUploadDialogRef } from './CertificateUploadDialog';
import { SnackNotificationContext } from "../../../legacy-components/notifications/GenericSnackNotification";
import { Alert } from "@material-ui/lab";

const mapState = () => ({

});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface CertificateSingleListProps {

}

const useStyles = makeStyles(({ palette }) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        title: {
            marginBottom: "15px"
        },
        tabs: {
            marginBottom: '30px'
        },
        container: {
            marginBottom: '10px'
        },
        icon: {
            color: palette.text.primary
        }
    }));

const Controls = ({ toCertficiateCreate }: { toCertficiateCreate: () => void }) => {
    return (
        <ResourceAbility can="create" resource={({ Certificate }) => [
            Certificate.create.environment["dev"],
            Certificate.create.environment["qa"],
            Certificate.create.environment["stage"]
        ]} fallBack={<ButtonGroup><Tooltip title={"Support for MTLS is deprecated"} placement="left" arrow><span><Button startIcon={<AddIcon />} onClick={toCertficiateCreate} variant="contained" color="primary" disabled>
        <FormattedMessage defaultMessage="Add Certificate" />
    </Button>
    </span></Tooltip></ButtonGroup>}>
        <ButtonGroup><Tooltip title={"Support for MTLS is deprecated"} placement="left" arrow><span><Button startIcon={<AddIcon />} onClick={toCertficiateCreate} variant="contained" color="primary" disabled>
                <FormattedMessage defaultMessage="Add Certificate" />
            </Button>
            </span></Tooltip></ButtonGroup>
        </ResourceAbility>
    )
}

const NotificationMessage = () => {
    return (
        <FormattedMessage defaultMessage="Certificate Uploaded Successfully" />
    )
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                    {/* <Typography>{children}</Typography> */}
                </Box>
            )}
        </div>
    );
}
const CertificateSingleList: FC<CertificateSingleListProps & PropsFromRedux & RouteComponentProps> = props => {
    const gridViewStyles = useGridViewStyles();

    const certificateDialogRef = useRef<CertificateUploadDialogRef>();
    const certificateGridRef = useRef<CertificateGridRef>();

    const openDialog = useCallback((handler: () => undefined) => {
        certificateDialogRef.current?.open();
    }, []);

    const handleOpenDialog = useCallback(() => openDialog(() => undefined), []);
    const notificationContext = useContext(SnackNotificationContext);
    const onSuccess = useCallback(() => {
        notificationContext.show({
            id: "certificate-upload-success",
            message: <NotificationMessage />
        });

        certificateGridRef.current?.refreshStream();
    }, []);
    const [can, state] = useResourceAbility({
        resource: ({ Certificate }) =>
            Certificate.list.environment["trimble-pre-prod"]
    });
    let orgs = useSelector((state: any) => state?.common?.gateway);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const classes = useStyles();

    return (

        <div className={gridViewStyles.root}>

            <CertificateUploadDialog ref={certificateDialogRef} onSuccess={onSuccess} />
            <GenericPageHeader title={<FormattedMessage defaultMessage="Certificates" />} controls={<Controls toCertficiateCreate={handleOpenDialog} />} />
            <Alert severity="info">
            Support for mTLS is deprecated.<br/>Post completion of api migration from Apigee to Azure, the option to edit/upload backend certificates will be removed, and the gateway will stop verifying backend certificates. Backends can still verify the authenticity of the request by verifying the certificate on the request. It is also expected that the backend server certificates are issued by well-known CAs trusted by the gateway. API Cloud gateway will continue to use Certificate Authentication to communicate to the backend.

            </Alert>
            {orgs && (<Tabs
                value={value}
                onChange={handleChange}
                className={classes.tabs}

            >

                {orgs.map((gateway: { name: string }) => (<Tab label={(gateway?.name?.toLowerCase() === 'trimble-pre-prod') ? "Pre Prod" : "Prod"} key={gateway.name} />))}


            </Tabs>)}
            {(state.loaded || state.intermediate) && can.read() && orgs && (
                <div
                >
                    {orgs && orgs.map((gateway: any, index: number) => (
                        <TabPanel value={value} index={index} key={index}>
                            <div className={gridViewStyles.content}>
                                <CertificateGrid ref={certificateGridRef} gateway={gateway.name} />
                            </div>
                        </TabPanel>
                    ))
                    }

                </div>
            )}
        </div >

    );
}

export default withTheme(withRouter(connector(CertificateSingleList)));