import React from "react";
import { NavLink } from "react-router-dom";
import {
  Avatar,
  Button,
  createStyles,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import BuildIcon from "@material-ui/icons/Build";
import LinkIcon from "@material-ui/icons/Link";
import { ResourceAbility } from "libs/security/authorization";
import { Feature } from "libs/security/authorization/FeatureAbility";
import { useFeatureAbility } from "libs/security/authorization/Permission";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: "30px",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    container: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    services: {
      [theme.breakpoints.up("md")]: {
        width: "50%",
      },
    },
    appLink: {
      textDecoration: "none",
      color: "#00437B !important",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  })
);
/**
 * @class ProxyCard
 * @description component contains Single card view for API Proxy listing
 */
const Starter = (props: any) => {
  const classes = useStyles();
  const [dense] = React.useState(false);

  const services: Array<any> = [
    {
      title: (
        <ResourceAbility
          can="read"
          resource={({ Application }) => [
            Application.list.environment["trimble-pre-prod"],
            Application.listByUser.environment["trimble-pre-prod"],
          ]}
          fallBack={<div>Identity Management</div>}
        >
          <NavLink className={classes.appLink} to={"/home/applications/list"}>
            Identity Management
          </NavLink>
        </ResourceAbility>
      ),
      description:
        "Before you start utilizing APIs, register your client applications with Identity 4.0.",
    },
    {
      title: (
        <>
          <NavLink className={classes.appLink} to={"/home/marketplace"}>
            Developer Marketplace
          </NavLink>
        </>
      ),
      description:
        "Discover and subscribe to published APIs from the API Marketplace.",
    },
    {
      title: (
        <ResourceAbility
          can="read"
          resource={({ Proxy }) => Proxy.list}
          fallBack={<div>API Management</div>}
        >
          <NavLink className={classes.appLink} to={"/home/proxy"}>
            API Management
          </NavLink>
        </ResourceAbility>
      ),
      description:
        "Manage and secure your APIs with the new API Management workspace. API Publishers can now collaborate with fellow team members and share APIs across teams.",
    },

  ];
  const helpLinks: Array<any> = [
    {
      title: "Trimble Cloud Platform Google Site",
      description: "To know more about Trimble Cloud services and Governance",
      link: "https://sites.google.com/trimble.com/trimblecloud",
    },
    {
      title: "Trimble Cloud Developer Documentation",
      description: "Having details on Trimble Core API documentation",
      link: "https://docs.trimblecloud.com/",
    },
    {
      title: "Support Ticket Tracking",
      description:
        "To raise a ticket and find solution for accessing Trimble Cloud Services.",
      link: "https://support.trimble.cloud/support/home",
    },
  ];

  return (
    <div className={classes.root}>
      <section>
        <Typography variant="h4" align="center" gutterBottom color="primary">
          Welcome to the Trimble Cloud Console!
        </Typography>
        <Typography variant="h6" align="center" gutterBottom color="secondary">
          A one-stop portal for accessing all Trimble Cloud Services
        </Typography>
      </section>

      <section className={classes.container}>
        <section className={classes.services}>
          <List
            dense={dense}
            component="nav"
            subheader={
              <Typography variant="h6" gutterBottom>
                Services
              </Typography>
            }
          >
            {services.map((service, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar>
                    <BuildIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={service.title}
                  secondary={service.description ?? null}
                />
              </ListItem>
            ))}
          </List>
        </section>
        <section>
          {" "}
          {/* Help Links */}
          <List
            dense={dense}
            component="nav"
            subheader={
              <Typography variant="h6" gutterBottom>
                Get Help
              </Typography>
            }
          >
            {helpLinks.map((helpLink, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar>
                    <LinkIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Link color="primary" href={helpLink.link} target="_blank">
                      {helpLink.title}
                    </Link>
                  }
                  secondary={helpLink.description ?? null}
                />
              </ListItem>
            ))}
          </List>
        </section>
      </section>
    </div>
  );
};
export default Starter;
