import { FC, useRef, useState } from "react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useGridViewStyles } from "legacy-components/grid-view/GridView";
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTheme, Box, Typography, Tabs, Tab, makeStyles, createStyles } from "@material-ui/core";
import GenericPageHeader from "legacy-components/page-headers/GenericPageHeader";
import ApprovalSubscriptionGrid, { ApprovalSubscriptionGridRef } from "./ApprovalSubscriptionGrid";
import SwipeableViews from "react-swipeable-views";
import { theme } from "themes/mui";


interface ApprovalSubscriptionListProps {

}

const mapState = () => ({

});


const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

const useStyles = makeStyles(({ palette }) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        title: {
            marginBottom: "15px"
        },
        tabs: {
            marginBottom: '30px'
        },
        container: {
            marginBottom: '10px'
        },
        icon: {
            color: palette.text.primary
        }
    }));

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                    {/* <Typography>{children}</Typography> */}
                </Box>
            )}
        </div>
    );
}

const ApprovalSubscriptionList: FC<ApprovalSubscriptionListProps & PropsFromRedux & RouteComponentProps> = props => {
    const gridViewStyles = useGridViewStyles();
    const ApprovalSubscriptionGridRef = useRef<ApprovalSubscriptionGridRef>();

    const [views, setViews] = React.useState([

        {
            mode: "SUBMISSION"
        },
        {
            mode: "REVIEW",
        }
    ]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    return (
        <div className={gridViewStyles.root}>
            <GenericPageHeader title={<FormattedMessage defaultMessage="Subscriptions" />} />
            {views && (<Tabs
                value={value}
                onChange={handleChange}
                className={classes.tabs}

            >
                {views.map((view: { mode: string }) => (<Tab label={(view?.mode?.toLowerCase() === 'submission') ? "Submitted" : "For Review"} key={view.mode} />))}


            </Tabs>)}

            {(views) && (
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    {views && views.map((view: any, index: number) => (
                        <TabPanel value={value} index={index} key={index}>
                            <div className={gridViewStyles.content}>
                                <ApprovalSubscriptionGrid ref={ApprovalSubscriptionGridRef} requestType={view.mode}></ApprovalSubscriptionGrid>

                            </div>
                        </TabPanel>
                    ))
                    }

                </SwipeableViews>
            )}
        </div >
    );
}
export default withTheme(withRouter(connector(ApprovalSubscriptionList)));