
import { Box } from "@material-ui/core";
import { Product, ProductStackCard } from "./ProductStackview";
import { AsyncActionState } from "legacy-components/form/action/AsyncAction";
import React from "react";
import { withViewController } from "modus/utilities/ViewController";
import { useProductListController } from "./ProductList.controller";
import { IProduct } from "pages/home/consumer/service/ProductService";
import { AxiosResponse } from "axios";
import _ from "lodash";
import { Skeleton } from "@material-ui/lab";
import { ErrorMessage } from "modus/components/error-message/ErrorMessage";

export interface ProductListViewModel {
    asyncResponse?: AsyncActionState<AxiosResponse<IProduct[]> | null>
}

export const ProductListView: React.FC<ProductListViewModel> = ({ asyncResponse }) => {
  console.log(asyncResponse)
    return (
      <Box mt={2} >
        {asyncResponse?.loading && ((new Array(10)).fill(1).map((num,index) => (
          <Box mb={"2px"}  key={index}>
          <Skeleton animation="wave" variant="rect" height={"70px"} width="100%" />
          </Box>
        )))}
        {asyncResponse?.loaded && asyncResponse?.value && asyncResponse?.value?.data?.map((product: IProduct) => {
          return (product && (<ProductStackCard {...product} />));
  } ) }
  {asyncResponse?.error && (
    <ErrorMessage error={asyncResponse?.errorValue}/>
  )}
      </Box>
    );
  };
  

  export const ProductList = withViewController(ProductListView, useProductListController);