import React, { FC, useCallback, useRef, useContext } from "react";
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTheme, Button, Tabs, Tab, makeStyles, createStyles, Box, ButtonGroup, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ResourceAbility } from "libs/security/authorization";
import { FormattedMessage } from "react-intl";
import GenericPageHeader from "../../../legacy-components/page-headers/GenericPageHeader";
import { useGridViewStyles } from "../../../legacy-components/grid-view/GridView";
import CertificateGrid, { CertificateGridRef } from "./CertificateGrid";
import CertificateUploadDialog, { CertificateUploadDialogRef } from './CertificateUploadDialog';
import { SnackNotificationContext } from "../../../legacy-components/notifications/GenericSnackNotification";
import SwipeableViews from "react-swipeable-views";
import { theme } from "themes/mui";
import { Alert } from "@material-ui/lab";
//import TabPanel from "@material-ui/lab/TabPanel";

const mapState = () => ({

});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface CertificateListProps {

}

const useStyles = makeStyles(({ palette }) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        title: {
            marginBottom: "15px"
        },
        tabs: {
            marginBottom: '30px'
        },
        container: {
            marginBottom: '10px'
        },
        icon: {
            color: palette.text.primary
        }
    }));

const Controls = ({ toCertficiateCreate }: { toCertficiateCreate: () => void }) => {
    return (
        <ResourceAbility can="create" resource={({ Certificate }) => [
            Certificate.create.environment["dev"],
            Certificate.create.environment["qa"],
            Certificate.create.environment["stage"],
            Certificate.create.environment["prod"]
        ]} fallBack={<ButtonGroup><Tooltip title={"Support for MTLS is deprecated"} placement="left" arrow><span><Button startIcon={<AddIcon />} onClick={toCertficiateCreate} variant="contained" color="primary" disabled>
        <FormattedMessage defaultMessage="Add Certificate" />
    </Button>
    </span></Tooltip></ButtonGroup>}>
        <ButtonGroup><Tooltip title={"Support for MTLS is deprecated"} placement="left" arrow><span><Button startIcon={<AddIcon />} onClick={toCertficiateCreate} variant="contained" color="primary" disabled>
                <FormattedMessage defaultMessage="Add Certificate" />
            </Button>
            </span></Tooltip></ButtonGroup>
        </ResourceAbility>
    )
}

const NotificationMessage = () => {
    return (
        <FormattedMessage defaultMessage="Certificate Uploaded Successfully" />
    )
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                    {/* <Typography>{children}</Typography> */}
                </Box>
            )}
        </div>
    );
}
const CertificateList: FC<CertificateListProps & PropsFromRedux & RouteComponentProps> = props => {
    const gridViewStyles = useGridViewStyles();

    const certificateDialogRef = useRef<CertificateUploadDialogRef>();
    const certificateGridRef = useRef<CertificateGridRef>();

    const openDialog = useCallback((handler: () => undefined) => {
        certificateDialogRef.current?.open();
    }, []);

    const handleOpenDialog = useCallback(() => openDialog(() => undefined), []);
    const notificationContext = useContext(SnackNotificationContext);
    const onSuccess = useCallback(() => {
        notificationContext.show({
            id: "certificate-upload-success",
            message: <NotificationMessage />
        });

        certificateGridRef.current?.refreshStream();
    }, []);
    let gateways = useSelector((state: any) => state?.common?.metadata?.gateways);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const classes = useStyles();

    return (
        <div className={gridViewStyles.root}>
            <CertificateUploadDialog ref={certificateDialogRef} onSuccess={onSuccess} />
            <GenericPageHeader title={<FormattedMessage defaultMessage="Certificates" />} controls={<Controls toCertficiateCreate={handleOpenDialog} />} />
            <Alert severity="info">
            Support for mTLS is deprecated.<br/>Post completion of api migration from Apigee to Azure, the option to edit/upload backend certificates will be removed, and the gateway will stop verifying backend certificates. Backends can still verify the authenticity of the request by verifying the certificate on the request. It is also expected that the backend server certificates are issued by well-known CAs trusted by the gateway. API Cloud gateway will continue to use Certificate Authentication to communicate to the backend.
            </Alert>
            {gateways && (<Tabs
                value={value}
                onChange={handleChange}
                className={classes.tabs}

            >

                {gateways.map((gateway: { name: string }) => (<Tab label={(gateway?.name?.toLowerCase() === 'trimble-pre-prod') ? "Pre Prod" : "Prod"} key={gateway.name} />))}


            </Tabs>)}
            {(gateways) && (
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    {gateways && gateways.map((gateway: any, index: number) => (
                        <TabPanel value={value} index={index} key={index}>
                            <div className={gridViewStyles.content}>
                                <CertificateGrid ref={certificateGridRef} gateway={gateway.name} />
                            </div>
                        </TabPanel>
                    ))
                    }

                </SwipeableViews>
            )}
        </div >
    );
}

export default withTheme(withRouter(connector(CertificateList)));