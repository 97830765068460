import React, { useState, useEffect } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useRole } from 'libs/security/authorization/Permission';
import { useAsyncAction } from 'legacy-components/form/action/AsyncAction';
import TeamServices from '../TeamService';
import { Typography } from '@material-ui/core';
import Loader from 'legacy-components/loader';
import { useStyles } from 'pages/home/products/product-forms/ProductProxyDialog';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: '0px',
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: "##0e416c",
      color: '#ffffff',
      border: '1px solid #4d7ca3',
      fontSize: 16,
      padding: '10px 26px 10px 12px',

      //transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "Roboto", "Helvetica", "Arial", "sans-serif"
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        color: '#ffffff',
        backgroundColor: "##0e416c"
      }
    },
  }),
)(InputBase);

const MY_TEAM_KEY = "MY_TEAM_KEY"
export default function CustomizedSelects(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [rolepermission, state] = useRole();
  const classes = useStyles();
  const [team, setTeam] = React.useState(sessionStorage.getItem(MY_TEAM_KEY) ?? rolepermission?.teamId);
  const [, , changeTeam] = useRole();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    toggleLoading(!isLoading);
    changeTeam(event.target.value as string);
    sessionStorage.setItem(MY_TEAM_KEY, event.target.value as string);
    setTeam(event.target.value as string);
    dispatch(history.push("/home"));
  };
  const [, setTeams] = useState<any>();

  const [isLoading, toggleLoading] = useState<boolean>(false);

  const getUserTeams = (teams: any) => {
    setTeams(teams);
    return teams;
  }
  const [response, { subscribe }] = useAsyncAction(() => TeamServices.getUsersTeam(),
    {
      mapFn: getUserTeams
    });

  useEffect(() => {

    setTeam(sessionStorage.getItem(MY_TEAM_KEY) ?? rolepermission?.teamId)
    changeTeam(sessionStorage.getItem(MY_TEAM_KEY) ?? rolepermission?.teamId);
    const unsubscribe = subscribe()

    return () => unsubscribe();
  }, [subscribe, (state.intermediate || state.loaded)]);

  useEffect(() => {

    toggleLoading(!isLoading);

  }, [state?.value?.teamId])

  return (
    <>
      {response?.value && isLoading &&
        <div className="team-selection-loader">
          <Loader />
        </div>

      }
      <div className="dropdown-white">

        {(response?.value && response?.value.count > 1 ? <FormControl>

          <InputLabel htmlFor="demo-customized-select-native">Teams</InputLabel>
          <NativeSelect
            id="demo-customized-select-native"
            value={team}
            onChange={handleChange}
            input={<BootstrapInput />}
          >

            {Object.keys(response?.value.values).map(key => (

              <optgroup key={key} label={key}>
                {response?.value.values[key].map((item: any, index: number) => (
                  <option key={index} value={item.team.id}>{item.team.displayName}</option>


                ))}
              </optgroup>

            ))}
          </NativeSelect>
        </FormControl>
          :
          <>
          <Typography variant="body2" gutterBottom className="sector">
            {props.group}
          </Typography>         
          </>
        )}
      </div>
    </>
  );
}

