import clsx from "clsx";

import React, { useEffect, useState } from "react";
import { useStatusBadgeSyles } from "./SubscriptionSummaryPage.style";
import { Box, Tabs, Tab, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RouteComponentProps, withRouter, useLocation, useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import ApplicationView from "pages/home/subscriptions/views/ApplicationView";
import { Grid } from "@material-ui/core";
import { of } from "rxjs";
import ProductView from "pages/home/subscriptions/views/ProductView";
import ConsumerSubscriptionService from "../services/ConsumerSubscriptionService";
import AccordionForm, { AccordionSummaryTitle } from "legacy-components/form/components/accordion-form/AccordionForm";
import { useAsyncAction } from "legacy-components/form/action/AsyncAction";
import { useResourceViewStyles } from "legacy-components/resource-view/ResourceView";
import GenericPageHeader from "legacy-components/page-headers/GenericPageHeader";
import { List } from "react-virtualized";

export interface IService{
  description: string;
  displayName: string;
  serviceName: string;
}

export interface ISubscriptionSummary{
  applicationId: string;
  applicationCreatedDate: string;
  applicationCreatedBy: string;
  
  applicationName: string;
  organisationName: string;
  productId: string;
  productName: string;
  productTeamName: string;
  services: Array<IService>;
  status: string;
  subscriberEmail: string;
  subscriptionId: string;
  subscriptionRequestedTime: string;
}

const ProductDetails = (
  <AccordionSummaryTitle>
      <FormattedMessage defaultMessage="Product" />
  </AccordionSummaryTitle>
);

const ApplicationDetails = (
  <AccordionSummaryTitle>
      <FormattedMessage defaultMessage="Application" />
  </AccordionSummaryTitle>
);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const SubscriptionSummaryPage: React.FC = ({}) => {
  
  const {subscriptionId} = useParams<any>();
  console.log("inside subscription summary");

  const [response, { subscribe }] = useAsyncAction(() => (ConsumerSubscriptionService.getSubscriptionSummary(subscriptionId)));
  const styles = useResourceViewStyles();

  useEffect(() => {
    if(!subscriptionId){
      return
    }
    subscribe();
  }, [subscriptionId]);

  
  // export default function SimpleAccordion() {
  const classes = useStyles();
    
  console.log(response?.value?.data);
  return (

    
    <div className={classes.root}>
      <GenericPageHeader title={<FormattedMessage defaultMessage="Subscriptions" />} />
          <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                  {response?.value?.data &&  <Accordion>
                    <AccordionForm id="product-summary">
                        <AccordionForm.type.Section id="product-detail" summary={ProductDetails}>
                          <div style={{ height: '100%' ,width:'100%', display:"inline-block",margin:"0px 24px"}}>
                              <ProductView subscription={response?.value?.data}/>
                            </div>
                        </AccordionForm.type.Section>
                    </AccordionForm>
                  </Accordion>}
              </Grid>
              <Grid item xs={12} md={6}>
                {response?.value?.data &&  <Accordion>
                  <AccordionForm id="application-summary">
                      <AccordionForm.type.Section id="application-detail" summary={ApplicationDetails}>
                      <div style={{ height: '100%' ,width:'100%', display:"inline-block",margin:"0px 24px"}}>
                            <ApplicationView subscription={response?.value?.data}/>
                          </div>
                      </AccordionForm.type.Section>
                  </AccordionForm>
                </Accordion>}
              </Grid>
      </Grid>
    </div>
  );
  
};

export default withRouter(SubscriptionSummaryPage);