import React, { FC, useState, useContext } from "react";
import { FormRenderProps, Field } from "react-final-form";
import {
  Button,
} from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  ProxyVerificationSecurityTsdlcInfo,
  ProxyVerificationSecurityInfo,
} from "../models/verification.model";
import FormContainer from "../../../../../../legacy-components/form/container/FormContainer";
import FormInputContainer from "../../../../../../legacy-components/form/container/FormInputContainer";
import { ValidatorBuilderContext } from "../../../../../../legacy-components/form/validator/Validator";
import ActionInliner from "../../../../../../legacy-components/form/action/ActionInliner";
import ProxyVerificationService from "../ProxyVerificationService";
import { FormApi, FORM_ERROR } from "final-form";
import { SnackNotificationContext } from "../../../../../../legacy-components/notifications/GenericSnackNotification";
import FormSelectionGroup from "legacy-components/form/input/FormSelectionGroup";
import AlertService from "legacy-components/alert/AlertService";
import Alert from "legacy-components/alert/Alert";

interface SecurityComplianceTsdlcFormProps {
  onCancel?: () => void;
  onSuccess?: (
    proxyVerificationSecurityTsdlcInfo: ProxyVerificationSecurityTsdlcInfo
  ) => void;
  proxyVerificationSecurityTsdlcInfo?: ProxyVerificationSecurityTsdlcInfo;
  proxy: string;
}

const NotificationMessage = () => {
  return (
    <FormattedMessage defaultMessage="Security Compliance TSDLC Saved Successfully" />
  );
};

const SecurityComplianceTsdlcForm: FC<SecurityComplianceTsdlcFormProps> = ({
  proxy,
  proxyVerificationSecurityTsdlcInfo,
  onSuccess,
}) => {
  const notificationContext = useContext(SnackNotificationContext);


  const onSubmit =
    () => (data: ProxyVerificationSecurityTsdlcInfo, form: FormApi) => {
      return new Promise((resolve) => {
        const formState = form.getState();
        if (formState.pristine) {
          resolve();
        } else {
          const securityComplianceInfo: ProxyVerificationSecurityInfo = {
            securityTsdlcWhitesource: data?.securityTsdlcWhitesource === undefined ?
            proxyVerificationSecurityTsdlcInfo?.securityTsdlcWhitesource : data?.securityTsdlcWhitesource,
            securityTsdlcVeracode: data?.securityTsdlcVeracode === undefined ?
            proxyVerificationSecurityTsdlcInfo?.securityTsdlcWhitesource : data?.securityTsdlcVeracode,
            securityTsdlcRapid7: data?.securityTsdlcRapid7 === undefined ?
            proxyVerificationSecurityTsdlcInfo?.securityTsdlcWhitesource : data?.securityTsdlcRapid7,
            securityTsdlcDoneQuaterly: data?.securityTsdlcDoneQuaterly === undefined ?
            proxyVerificationSecurityTsdlcInfo?.securityTsdlcWhitesource : data?.securityTsdlcDoneQuaterly
          };
          ProxyVerificationService.forSecurityCompliance(
            proxy,
            "tsdlc",
            securityComplianceInfo
          ).subscribe(
            () => {
              notificationContext.show({
                id: "security-compliance",
                message: <NotificationMessage />,
              });
              resolve();
            },
            (error) => {
              resolve({
                [FORM_ERROR]: error,
              });
            }
          );
        }
      });
    };
  const intl = useIntl();
  const [choices] = useState([
    {
      value: true,
      title: intl.formatMessage({
        defaultMessage: "Yes",
      }),
      // helperText: intl.formatMessage({
      //     defaultMessage: "Description of what TSDLC means"
      // })
    },
    {
      value: false,
      title: intl.formatMessage({
        defaultMessage: "No",
      }),
      // helperText: intl.formatMessage({
      //     defaultMessage: "Description of what TSDLC means"
      // })
    },
  ]);

  const validate = (values: ProxyVerificationSecurityTsdlcInfo) => {
    if (
      values.securityTsdlcWhitesource === null)
      AlertService.error({
        message:
          "Please fill out the mandatory fields. \n The following fields are required",
        isAutoHide: false,
        id: "security-compliance",
      });
  };
  const FormComponents = (
    formRenderProps: FormRenderProps<ProxyVerificationSecurityTsdlcInfo, {}>
  ) => {
    let formState = formRenderProps.form.getState();
    if (formState.submitFailed) {
      validate(formState.values);
    }
    return (
      <>
        <div>
          <Alert id="security-compliance" />
        </div>
        <div>
        <FormInputContainer
          title={
            <React.Fragment>
              <Typography
                variant="subtitle2"
                children={
                  <FormattedMessage defaultMessage="Do you do Open Source Scanning via WhiteSource or similar? *" />
                }
              />
            </React.Fragment>
          }
        >
          <>
            <Field
              name="securityTsdlcWhitesource"
              children={FormSelectionGroup}
              options={choices}
              // validate={validator.from({
              //   required: true,
              // })}
            />
          </>
        </FormInputContainer>

        <FormInputContainer
          title={
            <React.Fragment>
              <Typography
                variant="subtitle2"
                children={
                  <FormattedMessage defaultMessage="Do you do static code analysis scanning via Veracode or similar? *" />
                }
              />
            </React.Fragment>
          }
        >
          <>
            <Field
              name="securityTsdlcVeracode"
              children={FormSelectionGroup}
              options={choices}
              // validate={validator.from({
              //   required: true,
              // })}
            />
          </>
        </FormInputContainer>
        <FormInputContainer
          title={
            <React.Fragment>
              <Typography
                variant="subtitle2"
                children={
                  <FormattedMessage defaultMessage="Do you do Dynamic and/or API vulnerability scanning via Rapid7 or similar? *" />
                }
              />
            </React.Fragment>
          }
        >
          <>
            <Field
              name="securityTsdlcRapid7"
              children={FormSelectionGroup}
              options={choices}
              // validate={validator.from({
              //   required: true,
              // })}
            />
          </>
        </FormInputContainer>
        <FormInputContainer
          title={
            <React.Fragment>
              <Typography
                variant="subtitle2"
                children={
                  <FormattedMessage defaultMessage="Are all of the above done at least quarterly? *" />
                }
              />
            </React.Fragment>
          }
        >
          <>
            <Field
              name="securityTsdlcDoneQuaterly"
              children={FormSelectionGroup}
              options={choices}
              // validate={validator.from({
              //   required: true,
              // })}
            />
          </>
        </FormInputContainer>

        <ActionInliner>
          <Button
            disabled={formRenderProps.submitting || formRenderProps.pristine}
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
          >
            {" "}
            <FormattedMessage defaultMessage="Save" />
          </Button>
        </ActionInliner>
        </div>
      </>
    );
  };
  return (
    <FormContainer
      previewErrors={true}
      FormProps={{
        onSubmit,
        initialValues: {
          proxyVerificationSecurityTsdlcInfo,
        },
      }}
      onSubmitSuccess={onSuccess}
      children={FormComponents}
      prefillValues={proxyVerificationSecurityTsdlcInfo}
    />
  );
};

SecurityComplianceTsdlcForm.propTypes = {
  //proxyVerificationSecurityTsdlcInfo: PropTypes.any.isRequired
};

export default SecurityComplianceTsdlcForm;
