import React, { FC, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ProxyVerificationSecurityInfo } from "../models/verification.model";
import {
  AccordionContent,
  AccordionSummaryTitle,
} from "legacy-components/form/components/accordion-form/AccordionForm";
import InlineIconFormatter from "legacy-components/formatters/InlineIconFormatter";
import Helper from "pages/home/helper/Helper";
import JustificationHelper from "pages/home/helper/JustificationHelper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SecurityComplianceTsdlcForm from "./SecurityComplianceTsdlcForm";
import SecurityCompliancePrivacyForm from "./SecurityCompliancePrivacyForm";
import SecurityComplianceSovereigntyForm from "./SecurityComplianceSovereigntyForm";

interface SecurityComplianceFormProps {
  onCancel?: () => void;
  onSuccess?: (
    proxyVerificationSecurityInfo: ProxyVerificationSecurityInfo
  ) => void;
  proxyVerificationSecurityInfo?: ProxyVerificationSecurityInfo;
  proxy: string;
}

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    accordionSummary: {
      padding: "10px 15px",
      margin: 0,
      "&.Mui-expanded": {
        margin: " 0 !important",
        padding: "0 15px",
      },
    },
    accordionDetails: {
      display: "block !important",
      padding: 0,
    },
    root: {
      padding: spacing(2),
      textAlign: "center",
      width: "100%",
    },
    noConfigMessage: {
      color: palette.text.secondary,
    },
    noConfigAction: {
      padding: spacing(3, 0),
    },
    actions: {
      padding: spacing(0, 0, 3, 0),
    },
    content: {
      padding: spacing(3),
      overflow: "auto",
    },
    divider: {
      marginLeft: spacing(2),
    },
    textField1: {
      marginBottom: spacing(4),
      width: "100%",
    },
  })
);

const SecurityComplianceForm: FC<SecurityComplianceFormProps> = ({
  proxy,
  proxyVerificationSecurityInfo,
  onSuccess,
}) => {
  const proxyVerificationSecurityTsdlcInfo = {
    securityTsdlcWhitesource:
      proxyVerificationSecurityInfo?.securityTsdlcWhitesource,
    securityTsdlcRapid7: proxyVerificationSecurityInfo?.securityTsdlcRapid7,
    securityTsdlcVeracode: proxyVerificationSecurityInfo?.securityTsdlcVeracode,
    securityTsdlcDoneQuaterly:
      proxyVerificationSecurityInfo?.securityTsdlcDoneQuaterly,
  };

  const proxyVerificationSecurityPrivacyInfo = {
    securityDataPrivacyPii:
      proxyVerificationSecurityInfo?.securityDataPrivacyPii,
    securityDataPrivacyRopa:
      proxyVerificationSecurityInfo?.securityDataPrivacyRopa,
  };

  const proxyVerificationSecuritySovereigntyInfo = {
    securityDataSovereignty:
      proxyVerificationSecurityInfo?.securityDataSovereignty,
    dataSovereigntyRegion: proxyVerificationSecurityInfo?.dataSovereigntyRegion,
  };

  const classes = useStyles();
  const [isShowJustificationHelper, toggleJustificationHelper] =
    useState(false);
  return (
    <>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionSummary}
        >
          <AccordionSummaryTitle>
            <FormattedMessage defaultMessage="TSDLC" />
          </AccordionSummaryTitle>
          {proxyVerificationSecurityInfo?.securityTsdlcWhitesource &&
            proxyVerificationSecurityInfo?.securityTsdlcVeracode &&
            proxyVerificationSecurityInfo?.securityTsdlcRapid7 &&
            proxyVerificationSecurityInfo?.securityTsdlcDoneQuaterly && (
              <CheckCircleIcon color="primary" />
            )}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <AccordionContent
            size="small"
            header={
              <div>
                <InlineIconFormatter
                  icon={
                    <Typography
                      variant="caption"
                      color="error"
                      children={
                        <FormattedMessage defaultMessage="* required fields" />
                      }
                    />
                  }
                >
                  <FormattedMessage defaultMessage="TSDLC" />
                </InlineIconFormatter>
                <Helper
                  open={isShowJustificationHelper}
                  handleClose={() => {
                    toggleJustificationHelper(false);
                  }}
                  children={<JustificationHelper />}
                ></Helper>
              </div>
            }
          >
            <SecurityComplianceTsdlcForm
              proxy={proxy}
              onSuccess={onSuccess}
              proxyVerificationSecurityTsdlcInfo={
                proxyVerificationSecurityTsdlcInfo
              }
            />
          </AccordionContent>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionSummary}
        >
          <AccordionSummaryTitle>
            <FormattedMessage defaultMessage="Data Privacy" />
          </AccordionSummaryTitle>
          {proxyVerificationSecurityInfo?.securityDataPrivacyPii && (
            <CheckCircleIcon color="primary" />
          )}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <AccordionContent
            size="small"
            header={
              <div>
                <InlineIconFormatter
                  icon={
                    <Typography
                      variant="caption"
                      color="error"
                      children={
                        <FormattedMessage defaultMessage="* required fields" />
                      }
                    />
                  }
                >
                  <FormattedMessage defaultMessage="Data Privacy" />
                </InlineIconFormatter>
                <Helper
                  open={isShowJustificationHelper}
                  handleClose={() => {
                    toggleJustificationHelper(false);
                  }}
                  children={<JustificationHelper />}
                ></Helper>
              </div>
            }
          >
            <SecurityCompliancePrivacyForm
              proxy={proxy}
              onSuccess={onSuccess}
              proxyVerificationSecurityPrivacyInfo={
                proxyVerificationSecurityPrivacyInfo
              }
            />
          </AccordionContent>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionSummary}
        >
          <AccordionSummaryTitle>
            <FormattedMessage defaultMessage="Data Sovereignty" />
          </AccordionSummaryTitle>
          {proxyVerificationSecurityInfo?.securityDataSovereignty !== null && (
            <CheckCircleIcon color="primary" />
          )}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <AccordionContent
            size="small"
            header={
              <div>
                <InlineIconFormatter
                  icon={
                    <Typography
                      variant="caption"
                      color="error"
                      children={
                        <FormattedMessage defaultMessage="* required fields" />
                      }
                    />
                  }
                >
                  <FormattedMessage defaultMessage="Data Sovereignty" />
                </InlineIconFormatter>
                <Helper
                  open={isShowJustificationHelper}
                  handleClose={() => {
                    toggleJustificationHelper(false);
                  }}
                  children={<JustificationHelper />}
                ></Helper>
              </div>
            }
          >
            <SecurityComplianceSovereigntyForm
              proxy={proxy}
              onSuccess={onSuccess}
              proxyVerificationSecuritySovereigntyInfo={
                proxyVerificationSecuritySovereigntyInfo
              }
            />
          </AccordionContent>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

SecurityComplianceForm.propTypes = {
  //proxyVerificationSecurityInfo: PropTypes.any.isRequired
};

export default SecurityComplianceForm;
