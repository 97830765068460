
import * as actions from "../actions/actions.tsx";
import { updateObject } from "../../utils";

/**
 * @class Initializer
 * @description Mapping set of Reducers for Generic Actions
 * @param {Object} action - Action from action creator
 * @param {Object} state - Default application state
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action = {}) => {
     switch (action.type) {
          case actions.loadFeatures: return loadFeatures(state, action);
          case actions.toggleNavigationPanel: return toggleNavigationPanel(state, action);
          case actions.saveMetadata: return saveMetadata(state, action);
          default: return state;
     }
};



/**
 * @function loadFeatures
 * @description To save Feature Toggler Options
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const loadFeatures = (state, action) => {
     return updateObject(
          state, {
          features: action.features
     }
     );
}

/**
 * @function toggleNavigationPanel
 * @description To Update Breadcrumb value on change of links
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const toggleNavigationPanel = (state, action) => {
     return updateObject({
          isShowNavigationPanel: !state.isShowNavigationPanel
     });
}

const saveMetadata = (state, action) => {
     return updateObject({
          metadata: action.request,
          gateway: [action.request.gateways[0]]
     });
}

const INITIAL_STATE = {
     breadcrumbs: [],
     isShowNavigationPanel: true
};
