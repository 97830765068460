import { IAPIApplicationSubscription } from "libs/resources/subscription/SubscriptionService";
import { ITransformedSubscriptionList } from "pages/home/subscriptions/product-subscription-list/ProductSubscriptionList";
export const resolveText = (text: string) => {
    return text && text.split(/[-|_]/).join(" ");
  };
  
  export const resolveDate = (timestamp: string) => {
    const date = new Date(timestamp);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  export const notBlank: <T>(value: T) => boolean = <T>(value: T): value is T =>
  !(
    typeof value === "undefined" ||
    value === null ||
    (typeof value === "string" ? value.length < 1 : false) ||
    (Array.isArray(value) ? value.length < 1 : false) ||
    (typeof value === "boolean" && value === false)
  );
  
export const formatApplicationType = (data: string) => {
    return data === "resource" ? "proxy" : data;
};

export const resolveDay = (timestamp: string) => {
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
});
  return formattedDate;
};

export const productToApplicationMap = (data: Array<IAPIApplicationSubscription | null> | undefined) => {
  if (data !== undefined){
    const transformData: Array<ITransformedSubscriptionList>= []

    data?.forEach((item) => {
      if (!item)
        return
    const existingProduct = transformData.find((data) =>  data.productName === item.productName )
    const application = {
      applicationName: item.applicationName,
      consumerKey: item.consumerKey,
      gatewayName: item.gatewayName,
      status: item.status,
      subscriberEmail: item.subscriberEmail,
      subscriptionId: item.subscriptionId,
      subscriptionRequestedTime: item.subscriptionRequestedTime,
      productTeamName: item.productTeamName,
    }

    if (existingProduct) {
      existingProduct.applications.push(application);
    }
    else {
      transformData.push({
        productName: item.productName,
        applications: [application]
      })
    }
  })
    return transformData;
  }
  else
  {
    return undefined;
  }
}
