
import { Box, Grid, Paper, Select, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { APIDeployment, Service, Version } from "./Product.model";
import Table, { TableColumn } from "legacy-components/table/Table";
import CopyToClipboard from "legacy-components/form/action/CopyToClipboard";
// import { useProductSummaryController } from "./ProductSumma";

export interface ProductSummaryViewModel {
  services: Service[]
}


const Actions = (props: { data: any }) => {
  return (
    <CopyToClipboard size="small" edge="end" text={props?.data?.endpoint} key={props?.data?.endpoint} className={`copy-to-clipboard ${props?.data?.endpoint}`}/>
  );
};

export const ProductServicesViewer: React.FC<ProductSummaryViewModel> = ({ services }) => {
  const [selectedDisplayService, setSelectedDisplayService] = React.useState<string>(`${services[0]?.versions[0]?.versionName}`);
  const [selectedVersion, setSelectedVersion] = React.useState<Version>(services[0]?.versions[0]);
  const [selectedService, setSelectedService] = React.useState<Service>(services[0]);


  const onChange  = (service: Service, version: Version) => {
    setSelectedDisplayService(`${version.versionName}`);
    setSelectedService(service);
    setSelectedVersion(version);
}
    return (
      <Box>
        <Grid container>
          <Grid item md={6} xs={12} >
            <Box py={2}>
            {services?.length >= 1 && (<Select style={{backgroundColor: '#fff'}} fullWidth variant="outlined" value={selectedDisplayService}>
            {services?.map((service: Service) => {

              return [
                ...service.versions?.map((version) => {
                return (
                  <option onClick={() => onChange(service, version)} value={`${version.versionName}`}>{version.versionName}</option>
                );
              })
              ];
            
            })}
          </Select>)}
          </Box>
          </Grid>
          {/* <Grid item md={6} xs={12}>
          <Box p={2}>
            <Select fullWidth variant="outlined" defaultValue={services[0]?.versions[0]?.versionName}>
            {
              selectedVersion?.apiDeployments?.length > 0 && (<option value={"api" } >API</option>)
            }
            {
              selectedVersion?.eventDeployments?.length > 0 && (<option value={"events" } >Events</option>)
            }
          </Select>
          </Box> 
          </Grid> */}
        </Grid>
        
        {selectedVersion?.apiDeployments && (<APIServiceEndpoints apiDeployments={selectedVersion?.apiDeployments}/>)}
        {(!selectedVersion?.apiDeployments || selectedVersion?.apiDeployments?.length === 0) && (<Box m={1} p={3} border="1px solid #000">
          No Deployments Available
        </Box>)}
      </Box>
    );
  };
  
  interface IAPIServiceEndpoints {
    apiDeployments: APIDeployment[]
  }
  interface TransformedDeployment {
    endpoint?: string|null;
    geography?: string|null;
    isDeprecated?: boolean|undefined;
    provider?: string|null;
    type?: string|null;
    environment?: string|null;
  }
export const APIServiceEndpoints = ({apiDeployments}:IAPIServiceEndpoints) => {

  const [deploymentList, setDeploymentList] = React.useState<TransformedDeployment[]>();
  useEffect(() => {
    const extractedDeploymentlist:TransformedDeployment[] = [];
    apiDeployments.forEach(deployment => {
      deployment.instances.forEach(instance => {
        extractedDeploymentlist.push({
          ...instance,
          environment: (deployment.environment ?? "N/A"),
        });
      });
  });

  setDeploymentList(extractedDeploymentlist)

  },[apiDeployments]);
  return (
    <Paper style={{marginTop:"30px"}}>
      <Box p={2}>
        <Typography style={{fontSize:"14px", fontWeight: 'bold'}}>Service Endpoints</Typography>
        <Typography variant="body1" style={{fontSize:"14px", fontWeight: 'normal'}}>Please select the appropriate Service Endpoint needed for your Environment.</Typography>
      </Box>
      <Box p={2} style={{width:'100%'}}>
        <Table 
            rowData={deploymentList} 
            width='100%' height={"300px"} pagination={false}
            getRowNodeId={data => data.endpoint} 
            frameworkComponents={{Actions}}>
          <TableColumn field="endpoint" flex={2} cellRenderer={"Link"}/>
           <TableColumn field="environment"  flex={1}/>
          <TableColumn field="geography" headerName="Region" flex={1}/>
          <TableColumn field="action"  width={100} cellRenderer={"Actions"}/>
        </Table>
      </Box>
    </Paper>
  )

}
  export const ProductServices = ProductServicesViewer // withViewController(ProductSummaryView, useProductSummaryController);