import React, { useCallback} from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Icon, Tooltip } from '@material-ui/core';
import { CellClickedEvent } from "ag-grid-community";
import { useHistory } from 'react-router';
import { Alert } from '@material-ui/lab'; 
import { ITransformedSubscriptionList } from './ProductSubscriptionList';
import CreateIcon from '@material-ui/icons/Create';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded'
import Table, { TableColumn } from 'legacy-components/table/Table';
import { resolveDay } from 'legacy-components/Helper';
import { AccordionSummaryTitle } from 'legacy-components/form/components/accordion-form/AccordionForm';
import {Link} from '@material-ui/core';

export interface ProductSubscriptionListAccordianProps {
    data?: Array<ITransformedSubscriptionList>; // Page Title
  }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
    },
    accordionSummary: {
        padding: "10px 15px",
        margin: 0,
        borderBottom: '1px solid #f3f3f7',
        "&.Mui-expanded": {
          margin: " 0 !important",
          minHeight: "48px",
          borderLeft: "4px solid #0063a3",
          backgroundColor: "#dcedf9",
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0',
        },
      },
      accordionDetails: {
        borderLeft: "4px solid #0063a3"
      },
      table:{},
  }),

);

interface ProductSubscriptionListAccordionProps {
  data: (ITransformedSubscriptionList)[] | undefined;
}

export const ProductSubscriptionListAccordion: React.FC<ProductSubscriptionListAccordionProps> = ({
        data
      }) => {
  const classes = useStyles();
  const history = useHistory();

  const onCellClicked = useCallback((event : CellClickedEvent) => {
    history.push(`/home/applications/${event.data.applicationName}/gateway/${event.data.gatewayName}/summary`);
}, [history]);

const onClickProduct = useCallback((productName: string) => {
  console.log(productName);
  history.push(`/home/products/${productName}/summary`);
}, [history]);

const onEditClicked = useCallback((event : CellClickedEvent) => {
  console.log(event.data.subscriptionId);
  history.push(`/home/subscriptions/${event.data.subscriptionId}`);
}, [history]);

const action = () => {
  return <Box paddingTop={"7px"}
  >
      <CreateIcon></CreateIcon>
    </Box>
}

type ISubscriptionStatus =
  | "SUBSCRIBED"
  | "PENDING_REVIEW"
  | "REVOKED"
  | "DENIED"
  | "INITIALIZED";

const statusicon: React.FC<{ value: ISubscriptionStatus }> = ({ value }) => {
  return (
    <Box paddingTop={"7px"}>
    <Icon>
      {value === "SUBSCRIBED" &&  <Tooltip placement='right' title="Approved" children={<CheckCircleIcon style={{color: "green"}} />} />}
      {value === "DENIED" &&  <Tooltip placement='right' title="Denied" children={<CancelIcon color='error' />} /> }
      {value === "PENDING_REVIEW" &&  <Tooltip placement='right' title="In Review" children={<PeopleOutlineRoundedIcon />} /> }
      {value === "REVOKED" &&  <Tooltip placement='right' title="Revoked" children={<CancelIcon color='error' />} /> }
      {value === "INITIALIZED" &&  <Tooltip placement='right' title="In Review" children={<PeopleOutlineRoundedIcon />} /> }
    </Icon>
    </Box>
  );
};

  return (
    <div className={classes.root}>
      {data?.length === 0 && <Alert severity="info">{"No subscription found for your Applications"}</Alert>}
      {data?.map((item, index) => (
      <Accordion square >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <AccordionSummaryTitle>
              <Typography className={classes.heading} >
                  <Link onClick={()=>onClickProduct(item.productName)} >
                      {item.productName}
                  </Link>
              </Typography>
          </AccordionSummaryTitle>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Box style={{width: "100%"}}>
          <Table
          pagination={false}
          width={"100%"}
          height={"240px"}
              rowData={item.applications}
              noRowsText="No subscription found for your Products"
              data-testid="product-subscriptions-table"
              frameworkComponents={{ action, statusicon }}
            >
                <TableColumn
                flex={3}
                    field="applicationName"
                    headerName="Application Name"
                    data-testid="applicationName"
                    cellRenderer="Title"
                    onCellClicked = {(event) => onCellClicked(event)}
                    cellStyle={{color: '#0063a3'}}
                    sortable
                    unSortIcon = {true}
                    filter= "agTextColumnFilter"
                    filterParams= {{
                      suppressAndOrCondition: true,
                      defaultOption: 'contains',
                      filterOptions: ['contains']
                  }}
                />
              

                <TableColumn
                  flex={3}
                  field="subscriptionRequestedTime"
                  headerName="Subscribed Date"
                  data-testid="subscriptionRequestedTime"
                  valueFormatter={(params) => resolveDay(params.value)}
                  sortable
                    unSortIcon = {true}
                />

                <TableColumn
                  flex={2}
                  field="status"
                  headerName="Status"
                  data-testid="status"
                  cellRenderer="statusicon"
                />

                <TableColumn
                  width={150}
                  headerName="Actions"
                  data-testid="actions"
                  cellRenderer="action"
                  onCellClicked={(event) => onEditClicked(event)}
                />
            </Table>
          </Box>
            
        </AccordionDetails>
      </Accordion>
            ))}
    </div>
  );
}
