import React, { FC, memo, useCallback, useRef, useContext, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Badge, Chip, IconButton, Tooltip, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { ResourceAbility } from "libs/security/authorization";
import EditIcon from '@material-ui/icons/Edit';
import { sanitize } from "dompurify";
import { SnackNotificationContext } from "legacy-components/notifications/GenericSnackNotification";
import { StatusBadge, StatusBadgeInfo } from "legacy-components/badges/StatusBadge";
import { AccordionContent } from "legacy-components/form/components/accordion-form/AccordionForm";
import NAWrap from "legacy-components/form/action/NAWrap";
import FormViewInliner from "legacy-components/form/container/FormViewInliner";
import { ISubscriptionSummary } from "../subscription-summary-page/SubscriptionSummaryPage";

interface ProductViewProps {
    subscription: ISubscriptionSummary
}


const ProductView: React.FC<ProductViewProps> = ({subscription}) => {
    const notificationContext = useContext(SnackNotificationContext);
    const [publishStatus] = useState<StatusBadgeInfo>({
        kind: "access",
        key: subscription?.status,
        access: false
    });

    console.log(subscription);

    return (
        <>
            <AccordionContent size="small" />
                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Product Name" />}>
                    <Typography variant="body1">
                        {subscription?.productName}
                    </Typography>
                </FormViewInliner>
                
                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Subscribed Date" />}>
                    <Typography variant="body1">
                        {subscription?.subscriptionRequestedTime}
                    </Typography>
                </FormViewInliner>

                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Subscribed By" />}>
                    <Typography variant="body1">
                        {subscription?.subscriberEmail}
                    </Typography>
                </FormViewInliner>

                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage  defaultMessage="Status" />}>
                    <Typography variant="body1">
                        {/* <NAWrap value={subscription?.status} showMessage={true}> */}
                           <StatusBadge align="right" status={publishStatus} />
                        {/* </NAWrap> */}
                    </Typography>
                </FormViewInliner>
    </>
    )
}

//ProductView.propTypes = {
//     product: PropTypes.shape({
//         productId: PropTypes.string.isRequired,
//         clientId: PropTypes.string.isRequired,
//         createdDate: PropTypes.string.isRequired,
//         createdBy: PropTypes.string.isRequired,
//     }).isRequired,
// }

export default ProductView;

