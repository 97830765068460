import { IdentityApplication, IdentityApplicationPublishedGateway } from "../../../../libs/resources/identity/applications/PublisherApplicationService";
import React, { FC, memo, useCallback, useRef, useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Badge, Chip, IconButton, Tooltip, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { IdentityApplicationEnvironmentType } from '../../../../libs/resources/identity/applications/PublisherApplicationService';
import { CopyIdentityApplicationValues, IdentityApplicationCredentialsView } from "../../../../libs/resources/identity/applications/application-views/IdentityApplicationInfo";
import { ApplicationDetailTypeView } from "../../../../libs/resources/identity/applications/application-form/ApplicationConfigurationInfo";
import { ResourceAbility } from "libs/security/authorization";
import EditIcon from '@material-ui/icons/Edit';
import ApplicationSummaryDialog, { ApplicationSummaryDialogRef } from "../../../../libs/resources/identity/applications/application-form/summary/ApplicationSummaryDialog";
import { ApplicationSummaryFormData } from "../../../../libs/resources/identity/applications/models/application.model";
import { IdentityApplicationViewContext } from "../../../../libs/resources/identity/applications/IdentityApplicationView";
import { sanitize } from "dompurify";
import { SnackNotificationContext } from "legacy-components/notifications/GenericSnackNotification";
import { AccordionContent } from "legacy-components/form/components/accordion-form/AccordionForm";
import FormViewInliner from "legacy-components/form/container/FormViewInliner";
import NAWrap from "legacy-components/form/action/NAWrap";
import { ISubscriptionSummary } from "../subscription-summary-page/SubscriptionSummaryPage";

interface ApplicationViewProps {
    subscription: ISubscriptionSummary
}


const ApplicationView: React.FC<ApplicationViewProps> = ({subscription}) => {
    const applicationViewContext = useContext(IdentityApplicationViewContext);
    const notificationContext = useContext(SnackNotificationContext);
    const dialogRef = useRef<ApplicationSummaryDialogRef>();


    return (
        <>
            <AccordionContent size="small" />
                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Application Name" />}>
                    <Typography variant="body1">
                        {subscription?.applicationName}
                    </Typography>
                </FormViewInliner>
                
                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Client Id" />}>
                    <Typography variant="body1">
                        {subscription?.applicationId}
                    </Typography>
                </FormViewInliner>

                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Created Date" />}>
                    <Typography variant="body1">
                        {subscription?.applicationCreatedDate}
                    </Typography>
                </FormViewInliner>

                <FormViewInliner gutter="gutter-bottom" title={<FormattedMessage  defaultMessage="Created By" />}>
                    <Typography variant="body1">
                        {subscription?.applicationCreatedBy}
                    </Typography>
                </FormViewInliner>
    </>
    )
}

export default ApplicationView;

