import React, { FC, useState, useContext } from "react";
import { FormRenderProps, Field } from "react-final-form";
import { Button } from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  ProxyVerificationSecurityPrivacyInfo,
  ProxyVerificationSecurityInfo,
} from "../models/verification.model";
import FormContainer from "../../../../../../legacy-components/form/container/FormContainer";
import FormInputContainer from "../../../../../../legacy-components/form/container/FormInputContainer";
import { ValidatorBuilderContext } from "../../../../../../legacy-components/form/validator/Validator";
import ActionInliner from "../../../../../../legacy-components/form/action/ActionInliner";
import ProxyVerificationService from "../ProxyVerificationService";
import { FormApi, FORM_ERROR } from "final-form";
import { SnackNotificationContext } from "../../../../../../legacy-components/notifications/GenericSnackNotification";
import FormSelectionGroup from "legacy-components/form/input/FormSelectionGroup";
import AlertService from "legacy-components/alert/AlertService";
import Alert from "legacy-components/alert/Alert";
import FormTextInput from "legacy-components/form/input/FormTextInput";

interface SecurityCompliancePrivacyFormProps {
  onCancel?: () => void;
  onSuccess?: (
    proxyVerificationSecurityPrivacyInfo: ProxyVerificationSecurityPrivacyInfo
  ) => void;
  proxyVerificationSecurityPrivacyInfo?: ProxyVerificationSecurityPrivacyInfo;
  proxy: string;
}

const NotificationMessage = () => {
  return (
    <FormattedMessage defaultMessage="Security Compliance Data Privacy Saved Successfully" />
  );
};

const SecurityCompliancePrivacyForm: FC<SecurityCompliancePrivacyFormProps> = ({
  proxy,
  proxyVerificationSecurityPrivacyInfo,
  onSuccess,
}) => {
  const notificationContext = useContext(SnackNotificationContext);

  const onSubmit =
    () => (data: ProxyVerificationSecurityPrivacyInfo, form: FormApi) => {
      return new Promise((resolve) => {
        const formState = form.getState();

        if (formState.pristine) {
          resolve();
        } else {
          const securityComplianceInfo: ProxyVerificationSecurityInfo = {
            securityDataPrivacyPii:
              data?.securityDataPrivacyPii === undefined
                ? proxyVerificationSecurityPrivacyInfo?.securityDataPrivacyPii
                : data?.securityDataPrivacyPii,
            securityDataPrivacyRopa:
              data?.securityDataPrivacyRopa === undefined
                ? proxyVerificationSecurityPrivacyInfo?.securityDataPrivacyRopa
                : data?.securityDataPrivacyRopa,
          };
          ProxyVerificationService.forSecurityCompliance(
            proxy,
            "privacy",
            securityComplianceInfo
          ).subscribe(
            () => {
              notificationContext.show({
                id: "security-compliance",
                message: <NotificationMessage />,
              });
              resolve();
            },
            (error) => {
              resolve({
                [FORM_ERROR]: error,
              });
            }
          );
        }
      });
    };
  const intl = useIntl();
  const validator = useContext(ValidatorBuilderContext);
  
  const [privacies] = useState([
    {
      value: true,
      title: intl.formatMessage({
        defaultMessage: "Yes",
      }),
      helperText: intl.formatMessage({
        defaultMessage: "If Yes, a ROPA is required before you may proceed.",
      }),
    },
    {
      value: false,
      title: intl.formatMessage({
        defaultMessage: "No",
      }),
      helperText: intl.formatMessage({
        defaultMessage: "If No, you may proceed.",
      }),
    },
  ]);
  const [] = useState(false);

  const validate = (values: ProxyVerificationSecurityPrivacyInfo) => {
    if (values.securityDataPrivacyPii === null)
      AlertService.error({
        message:
          "Please fill out the mandatory fields. \n The following fields are required",
        isAutoHide: false,
        id: "security-compliance",
      });
  };
  const FormComponents = (
    formRenderProps: FormRenderProps<ProxyVerificationSecurityPrivacyInfo, {}>
  ) => {
    let formState = formRenderProps.form.getState();
    if (formState.submitFailed) {
      validate(formState.values);
    }
    return (
      <>
        <div>
          <Alert id="security-compliance" />
        </div>

        <FormInputContainer
          title={
            <React.Fragment>
              <Typography
                variant="subtitle2"
                children={
                  <FormattedMessage defaultMessage="Does your API Proxy handle PII? *" />
                }
              />
            </React.Fragment>
          }
        >
          <>
            <Field
              name="securityDataPrivacyPii"
              children={FormSelectionGroup}
              options={privacies}
              // validate={validator.from({
              //   required: true,
              // })}
            />
          </>
        </FormInputContainer>
        {formState.values && formState.values.securityDataPrivacyPii === true && (
          <FormInputContainer
            title={
              <Typography
                variant="subtitle2"
                children={<FormattedMessage defaultMessage="ROPA *" />}
              />
            }
          >
            <Field
              name="securityDataPrivacyRopa"
              type="text"
              component={FormTextInput}
              validate={validator.from({
                maxLength: 600,
                pattern: ({ HttpUrl }) => HttpUrl,
              })}
              placeholder={intl.formatMessage({
                defaultMessage: "https://www.your-ropa.com/",
              })}
            />
          </FormInputContainer>
        )}

        <ActionInliner>
          <Button
            disabled={formRenderProps.submitting || formRenderProps.pristine}
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
          >
            {" "}
            <FormattedMessage defaultMessage="Save" />
          </Button>
        </ActionInliner>
      </>
    );
  };
  return (
    <FormContainer
      previewErrors={true}
      FormProps={{
        onSubmit,
        initialValues: {
          securityDataPrivacyPii:
            proxyVerificationSecurityPrivacyInfo?.securityDataPrivacyPii,
          securityDataPrivacyRopa:
            proxyVerificationSecurityPrivacyInfo?.securityDataPrivacyRopa,
        },
      }}
      onSubmitSuccess={onSuccess}
      children={FormComponents}
      prefillValues={proxyVerificationSecurityPrivacyInfo}
    />
  );
};

SecurityCompliancePrivacyForm.propTypes = {
  //proxyVerificationSecurityPrivacyInfo: PropTypes.any.isRequired
};

export default SecurityCompliancePrivacyForm;
