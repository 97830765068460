import React, { FC, useCallback } from "react";
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { withTheme, Button, makeStyles, createStyles, Box, Tabs, Tab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ResourceAbility } from "libs/security/authorization";
import { FormattedMessage } from "react-intl";
import GenericPageHeader from "../../../../legacy-components/page-headers/GenericPageHeader";
import { useGridViewStyles } from "../../../../legacy-components/grid-view/GridView";
import IdentityApplicationGrid from "./IdentityApplicationGrid";
import SwipeableViews from "react-swipeable-views";
import { theme } from "themes/mui";
import ApplicationBasicForm from "./application-form/ApplicationBasicForm";
import Dialog, { useDialogProps } from "legacy-components/dialog/Dialog";

const mapState = () => ({

});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IdentityApplicationListProps {

}

const useStyles = makeStyles(({ palette }) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        title: {
            marginBottom: "15px"
        },
        tabs: {
            marginBottom: '30px'
        },
        container: {
            marginBottom: '10px'
        },
        icon: {
            color: palette.text.primary
        }
    }));

const Controls = ({ toApplicationCreate }: { toApplicationCreate: () => void }) => {
    return (
        <ResourceAbility can="create" resource={({ Application }) => [
            Application.create.environment["trimble-prod"],
            Application.create.environment["trimble-pre-prod"]
        ]}>
            <Button startIcon={<AddIcon />} onClick={toApplicationCreate} variant="contained" color="primary">
                <FormattedMessage defaultMessage="Add Application" />
            </Button>
        </ResourceAbility>
    )
}


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                    {/* <Typography>{children}</Typography> */}
                </Box>
            )}
        </div>
    );
}

const IdentityApplicationList: FC<IdentityApplicationListProps & PropsFromRedux & RouteComponentProps> = props => {
    const gridViewStyles = useGridViewStyles();

    let location = useLocation();
    const {open: openAppCreate, toggle} = useDialogProps();

       const toApplicationCreate = useCallback(() => {
        toggle();
    }, [toggle]);

    let gateways = useSelector((state: any) => state?.common?.metadata?.gateways);
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const classes = useStyles();
    return (
        <div className={gridViewStyles.root}>
            <GenericPageHeader title={<FormattedMessage defaultMessage="Applications" />} controls={<Controls toApplicationCreate={toApplicationCreate} />} />
            <Dialog open={openAppCreate} title="Create New Application" onClose={toggle}>
                <div>
                    <ApplicationBasicForm productName={(location?.state as any)?.productName} stepperId="application-creation" onCancel={toggle}/>
                </div>
            </Dialog>
            {gateways && (<Tabs
                value={value}
                onChange={handleChange}
                className={classes.tabs}

            >
                {gateways.map((gateway: { name: string }) => (<Tab label={(gateway?.name?.toLowerCase() === 'trimble-pre-prod') ? "Pre Prod" : "Prod"} key={gateway.name} />))}


            </Tabs>)}
            {(gateways) && (
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    {gateways && gateways.map((gateway: any, index: number) => (
                        <TabPanel value={value} index={index} key={index}>
                            <div className={gridViewStyles.content}>

                                <ResourceAbility can="create" resource={({ TeamMembers }) => TeamMembers.invite}
                                    fallBack={<IdentityApplicationGrid forUser={true} gateway={gateway.name} />} >
                                    <IdentityApplicationGrid gateway={gateway.name} />
                                </ResourceAbility>
                            </div>
                        </TabPanel>
                    ))
                    }

                </SwipeableViews>
            )}
        </div >
    );
}

export default withTheme(withRouter(connector(IdentityApplicationList)));