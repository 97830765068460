import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import ProfileService, { UserProfileAPI } from "../services/profileService";

import * as actions from "../store/actions/actions";
import {
  Box,
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";

import SearchReceiver from "header/SearchReceiver";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  useRole,
  useFeatureAbility,
  useResourceAbility,
} from "libs/security/authorization/Permission";
import { Feature } from "libs/security/authorization/FeatureAbility";
import CertificateIcon from "./icons/CertificateIcon";
import SectorIcon from "./icons/SectorIcon";
import ProductIcon from "./icons/productIcon";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import PeopleOutlineRoundedIcon from "@material-ui/icons/PeopleOutlineRounded";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TeamSelectionForm from "pages/home/team/forms/TeamSelectionForm";
import { NavigationPanel } from "./navigation-menu/NavigationPanel";
import { NavigationSkeleton } from "./NavigationSkeleton";

interface VerticalNavigationProps {
  isShow: boolean;
  isShowFullSize: boolean;
}
const VerticalNavigation: React.FC<VerticalNavigationProps> = ({
  isShow,
  isShowFullSize,
}) => {
  const globalUser = useSelector((globalState: any) => globalState.userManager);
  const [isMainMenuOpen, toggleMainMenuOpen] = useState<any>({});
  const [rolepermission, state] = useRole();
  const [user, setUser] = useState<any>();
  const [photo, setPhoto] = useState<string>();
  const dispatch = useDispatch();

  const hasFeature = useFeatureAbility({
    id: Feature.ApiVerification,
  });

  const [,] = useResourceAbility({
    resource: ({ Certificate }) => Certificate.list.environment["trimble-prod"],
  });
  const verticalMenuDetails: any = {};

  verticalMenuDetails["api_management"] = {
    name: "API Management",
    url: "/home/proxy",
    isApprovalNeeded: true,
    waitingUrl: "/home/noaccess",
  };
  verticalMenuDetails["api_proxy"] = {
    name: "API Proxy",
    icon: <CodeRoundedIcon style={{ color: "rgba(255, 255, 255, 0.72)" }} />,
    url: "/home/proxy",
    isApprovalNeeded: true,
    waitingUrl: "/home/noaccess",
  };

  verticalMenuDetails["api_products"] = {
    name: "API Products",
    icon: <ProductIcon style={{ color: "rgba(255, 255, 255, 0.72)" }} />,
    url: "/home/products",
    isApprovalNeeded: true,
    waitingUrl: "/home/noaccess",
  };
  verticalMenuDetails["truststore_cert"] = {
    name: "Certificates",
    icon: <CertificateIcon style={{ color: "rgba(255, 255, 255, 0.72)" }} />,
    url: "/home/certificates",
    deprecated: true,
  };

  verticalMenuDetails["my_teams"] = {
    name: "Teams",
    icon: <SectorIcon style={{ color: "rgba(255, 255, 255, 0.72)" }} />,
    url: `/home/teams/${rolepermission?.sector}/list`,
  };
  verticalMenuDetails["individual_users"] = {
    name: "My Team",
    icon: <PeopleOutlineRoundedIcon style={{ color: "rgba(255, 255, 255, 0.72)" }} />,
    url: `/home/teams/${rolepermission?.team}/users`,
  };
  verticalMenuDetails["user_management"] = {
    name: "User Management",
    icon: <PeopleOutlineRoundedIcon style={{ color: "rgba(255, 255, 255, 0.72)" }} />,
  };
  
  verticalMenuDetails["approvals"] = {
    name: "Approvals",
    icon: <AssignmentIcon style={{ color: "rgba(255, 255, 255, 0.72)" }} />,
    url: "/home/approvals",
  };
  verticalMenuDetails["prod_subscription"] = {
    name: "Subscription",
    icon: <AssignmentIcon style={{ color: "rgba(255, 255, 255, 0.72)" }} />,
    url: "/home/approvals/prod-subscription",
  };

  if (hasFeature) {
    verticalMenuDetails["approvals"] = {
      name: "Approvals",
      icon: <AssignmentIcon style={{ color: "rgba(255, 255, 255, 0.72)" }} />,
      url: "/home/approvals",
    };
    verticalMenuDetails["api_verification"] = {
      name: "API Proxies",
      icon: <CodeRoundedIcon style={{ color: "rgba(255, 255, 255, 0.72)" }} />,
      url: "/home/approvals/proxy",
    };
  }

  const loadMyPhoto = useCallback((id: string) => {
    let apiCall = UserProfileAPI.get_my_photo;
    let request = {
      userId: id,
    };
    ProfileService.request({
      url: UserProfileAPI.parseRequestURL(apiCall, request),
      method: "GET",
    }).then((response: any) => {
      if (response?.data?.length > 0) {
        dispatch({
          type: actions.storeUserDetails,
          payload: {
            photo: response.data[0].imageUrl,
          },
        });
        setPhoto(response.data[0].imageUrl);
      }
    });
  }, []);
  const loadMyDetails = useCallback(() => {
    let apiCall = UserProfileAPI.get_my_details;
    let request: any = {
      storeId: process.env.REACT_APP_STORE_ID,
    };
    if (globalUser.firstName) {
      setUser({
        firstName: globalUser.firstName,
        lastName: globalUser.lastName,
      });

      setPhoto(globalUser.photo);
    } else {
      ProfileService.request({
        url: UserProfileAPI.parseRequestURL(apiCall, request),
        method: "GET",
      }).then((response: any) => {
        dispatch({
          type: actions.storeUserDetails,
          payload: {
            id: response.data.id,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            emails: response.data.emails,
          },
        });
        setUser({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
        });
        loadMyPhoto(response.data.id);

        // RoleService.useEmail(response?.data?.emails?.[0]?.address);
      });
    }
  }, []);

  useEffect(() => {
    if ((state.loaded || state.intermediate) && !globalUser.role) {
      dispatch({
        type: actions.storeUserRole,
        payload: rolepermission,
      });
    }

    loadMyDetails();
  }, [
    loadMyDetails,
    globalUser.role,
    dispatch,
    rolepermission,
    state.loaded,
    state.intermediate,
  ]);

  const toggleMainMenu = useCallback(
    (index: string) => {
      let toggler = { ...isMainMenuOpen };
      toggler[index] = !toggler[index];
      toggleMainMenuOpen(toggler);
    },
    [isMainMenuOpen]
  );

  let navigationMenuList: Array<any> = [];

  const generateNavigationMenu = () => {
    if (rolepermission?.menus?.length > 0) {
      rolepermission?.menus?.forEach((mainMenu: any) => {
        let subMenuList: Array<any> = [];
        mainMenu.menus.forEach((subMenu: any) => {
          if (verticalMenuDetails[subMenu.key]) {
            let subMenuItem = (
              <ListItem
                button
                className={
                  rolepermission?.approved === "INITIATED" &&
                  verticalMenuDetails[subMenu.key].isApprovalNeeded
                    ? "disabled-nav-link"
                    : ""
                }
              >
                <ListItemIcon>
                  {verticalMenuDetails[subMenu.key].icon}
                </ListItemIcon>
                <ListItemText
                  className="sub-menu-text"
                  primary={<Box display="flex"><Box flexGrow={1}>{verticalMenuDetails[subMenu.key].name}</Box>
                                <Box>{verticalMenuDetails[subMenu.key]?.deprecated && <Chip label="deprecated" size="small"/> }</Box>  </Box>}
                />
              </ListItem>
            );

            if (
              !verticalMenuDetails[subMenu.key].externalLink ||
              (rolepermission?.approved === "INITIATED" &&
                verticalMenuDetails[subMenu.key].isApprovalNeeded)
            ) {
              let navUrl = verticalMenuDetails[subMenu.key].url;

              if (
                rolepermission?.approved === "INITIATED" &&
                verticalMenuDetails[subMenu.key].isApprovalNeeded
              ) {
                navUrl = verticalMenuDetails[subMenu.key].waitingUrl;
              }
              subMenuList.push(
                <NavLink
                  className="navigation-tab"
                  key={subMenu.key}
                  activeClassName="active"
                  to={navUrl}
                >
                  {subMenuItem}
                </NavLink>
              );
            } else {
              subMenuList.push(
                <a
                  className="navigation-tab"
                  key={subMenu.key}
                  href={verticalMenuDetails[subMenu.key].url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {subMenuItem}
                </a>
              );
            }
          }
        });
        let mainMenuItem = (
          <Collapse
            in={!isMainMenuOpen[mainMenu.key]}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {subMenuList}
            </List>
          </Collapse>
        );
        if (verticalMenuDetails[mainMenu.key]) {
          navigationMenuList.push(
            <List component="nav" key={mainMenu.key}>
              <ListItem
                button
                onClick={() => {
                  toggleMainMenu(mainMenu.key);
                }}
                className={                  
                  rolepermission?.approved === "INITIATED" &&
                  verticalMenuDetails[mainMenu.key].isApprovalNeeded
                    ? "disabled-nav-link nav-link-item"
                    : "nav-link-item"
                }
              >
                <ListItemText
                  className="main-menu-text"
                  primary={verticalMenuDetails[mainMenu.key].name}
                />
                {isMainMenuOpen[mainMenu.key] ? (
                  <ExpandLess className="main-menu-drop-icon" />
                ) : (
                  <ExpandMore className="main-menu-drop-icon" />
                )}
              </ListItem>
              {mainMenuItem}
            </List>
          );
        }
      });
    }
  };

  generateNavigationMenu();
  useEffect(() => {
    generateNavigationMenu();
  }, []);

  let group;
  if (state.loaded || state.intermediate) {
    group =
      globalUser.role === "SECTOR ADMIN" ? globalUser.sector : globalUser.teamDisplayName;
  }

  return (
    <>
      <div
        className={
          "vertical-navigation" +
          (isShow ? " nav-show" : "") +
          (!isShowFullSize ? " short-view" : " ")
        }
      >
        <NavLink className="user" to={"/home/profile"}>
          <div className="photo-block">
            {photo && <img src={photo} className="photo" alt="user" />}
            {!photo && (
              <img
                src="/assets/images/user-default.png"
                className="photo"
                alt="user"
              />
            )}
          </div>
          {user?.firstName && (
            <div className="name">
              {user.firstName} {user.lastName}
            </div>
          )}

          <Typography variant="subtitle2" gutterBottom className="sector">
            {rolepermission?.role}
          </Typography>
        </NavLink>

        <TeamSelectionForm group={group} />
        <SearchReceiver className="search-bar" />
        <div className="navigation-panel">
          {navigationMenuList?.length > 0 && (
            <>
              <NavigationPanel/>
              {navigationMenuList}
            </>)
          }
          {
            (!navigationMenuList || navigationMenuList.length === 0) &&  
            (  <NavigationSkeleton/>
            )
          }
          </div>
      </div>
    </>
  );
};

export default VerticalNavigation;
