

import { from } from "rxjs";
import { APIMethod, APIResource } from "libs/utils/http/decorator/UrlDecorator";
import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";
import { ConsumerClient2 } from "pages/home/consumer/service/ConsumerClient";
import { ISubscriptionSummary } from "../subscription-summary-page/SubscriptionSummaryPage";

export interface ISubscriptionRequest {
  applicationName: string;
  gatewayName: string;
  businessContext?: string | null;
}

@APIResource(ConsumerClient2, "")
class ConsumerSubscriptionServiceImpl {
  

  @APIMethod("/subscriptions/:subscriptionId")
  public getSubscriptionSummary(subscriptionId: string, org?: string) {
    return from(
        ConsumerClient2.get<ISubscriptionSummary>(apiMethodContext(arguments), {
        params: {
          subscriptionId,
          // org
        },
      })
    );
  }

}

const ConsumerSubscriptionService = new ConsumerSubscriptionServiceImpl();

export default ConsumerSubscriptionService;


