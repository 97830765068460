import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import MenuIcon from '@material-ui/icons/Menu';
import UserPanel from './UserPanel';
import LanguageMenu from './LanguageMenu';
import MainHelper from 'pages/home/helper/MainHelper';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import { useHistory } from 'react-router';
import Notification from './Notification';
import CloudConsoleLogo from 'legacy-components/icons/CloudConsoleLogo';
import CompanyLogo from 'legacy-components/icons/CompanyLogo';
import { theme } from 'themes/mui';
import { RelatedWebApps } from './RelativeApps';
import TeamSelectionForm from 'pages/home/team/forms/TeamSelectionForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: 0,
      width: "inherit"
    },
    appBar: {
      background: "#fff",
      color: "#000",
      userSelect: "none"
    },
    toolBar: {
      width: "inherit",
      margin: 0,
      minHeight: '56px'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1
    },
    searchDesktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      }
    },
    settings: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      },
    },

  }),
);

interface HeaderProps {
  toggleNav: () => void
  toggleNavSize: () => void
}
const Header: React.FC<HeaderProps> = ({ toggleNav, toggleNavSize }) => {
  const classes = useStyles();
  const [openHelper, setOpenHelper] = useState(false);
  const history = useHistory();

  const onHelpClicked = () => {
    setOpenHelper(true);
  }
 

  const toggleNavigationPanel = () => {
    toggleNavSize();
  }
  const toggleMobileNavigationPanel = () => {
    toggleNav();
  }
  let search;
  // search = (<SearchReceiver className={classes.searchDesktop}/>);
  let settings = (
    <div className={classes.settings} >
      <RelatedWebApps/>
      <IconButton onClick={onHelpClicked}>
        <HelpOutlineIcon />
      </IconButton>
      <Notification />
      <MainHelper open={openHelper} handleClose={() => { setOpenHelper(false) }} />
      <LanguageMenu />
      <IconButton><AppsOutlinedIcon /></IconButton>
      <UserPanel />
    </div>
  );
  /**
   * Hiding Search and Settings temporarily until features are implemented
   */
  settings = (
    <div className={classes.settings} >
      <RelatedWebApps/>
      <Notification />
      <IconButton onClick={onHelpClicked}>
        <HelpOutlineIcon />
      </IconButton>
      <MainHelper open={openHelper} handleClose={() => { setOpenHelper(false) }} />
      <UserPanel />
    </div>
  );

  const onHomeClicked = () => {
    history.push('/home')
  }
  return (
    <div className={classes.root}>
        <AppBar position="relative" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              edge="start"
              className="desktop-view"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleNavigationPanel}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              edge="start"
              className="mobile-view"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleMobileNavigationPanel}
            >
              <MenuIcon />
            </IconButton>
            <CompanyLogo />
            <CloudConsoleLogo onClick={onHomeClicked} />
            <span className={classes.title} />
            {search}
            {settings}
          </Toolbar>
        </AppBar>
    </div>
  );
}


export default Header;
