import { useIntl, FormattedMessage } from "react-intl";
import { useState, FC, useCallback, useEffect } from "react";
import { IdentityApplicationPublishedGateway, IdentityApplicationTypes } from "../PublisherApplicationService";
import NAWrap from "../../../../../legacy-components/form/action/NAWrap";
import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { mapToArray } from "../../../../utils/shared/common";

export const useApplicationConfigurationInfo = () => {
    const intl = useIntl();

    const [applicationTypes] = useState([
        {
            value: "service-application",
            title: intl.formatMessage({
                defaultMessage: "Service Application"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Service accounts are used to establish programmatic workflows, such as for server-to-server communication or for establishing accounts for build servers."
            }),
            enabled: true
        }, {
            value: "application",
            title: intl.formatMessage({
                defaultMessage: "Application"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "An application represents the Trimble software application that end users will access. It stores your credentials and contains configuration options to manage how users access your application."
            }),
            enabled : true
        }, {
            value: "resource",
            title: intl.formatMessage({
                defaultMessage: "resource"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "An resource represents the Trimble software application that end users will access. It stores your credentials and contains configuration options to manage how users access your application."
            }),
            enabled : false
        }
    ]);

    const [authenticationMethods] = useState([
        {
            value: "authorization_code",
            title: intl.formatMessage({
                defaultMessage: "Authorization Code Grant"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "The Authorization Code Grant allows confidential and public clients to exchange an authorization code for an access token via a trusted back channel."
            })
        },
        {
            value: "client_credentials",
            title: intl.formatMessage({
                defaultMessage: "Client Credentials Grant"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "The Client Credentials Grant allows clients to obtain an access token outside of the context of a user with a secured environments."
            })
        },
        {
            value: "urn:ietf:params:oauth:grant-type:device_code",
            title: intl.formatMessage({
                defaultMessage: "Device Code"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "The Device Code grant type allows input-constrained or no-browser devices to exchange a previously obtained code for an access token."
            })
        },
        {
            value: "implicit",
            title: intl.formatMessage({
                defaultMessage: "Implicit Grant"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "The Implicit Grant allows clients to obtain an access token without an intermediate code exchange step, rather than being returned via a trusted back channel like in the Authorization Code Grant."
            })
        },
        {
            value: "refresh_token",
            title: intl.formatMessage({
                defaultMessage: "Use Refresh Tokens"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "A refresh token allows an application to obtain a new access token without prompting the user for specified amount of time."
            })
        },
        {
            value: "urn:ietf:params:oauth:grant-type:token-exchange",
            title: intl.formatMessage({
                defaultMessage: "Token Exchange"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Sharing of identity and security information in heterogeneous environments or across security domains."
            })
        }]);

    const [protocolMethods] = useState([
        {
            value: "saml",
            title: intl.formatMessage({
                defaultMessage: "Security Assertion Markup Language (SAML)"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Open standard for exchanging authentication and authorization data between parties, in particular, between an identity provider and a service provider."
            })
        }
    ]);

    const [availableScopeValidationMethods] = useState([
        {
            value: "scope_validation",
            title: intl.formatMessage({
                defaultMessage: "Enable Scope Validation"
            }),
            helperText: intl.formatMessage({
                defaultMessage: "Enforces strict validation on the requested scopes for the application"
            }),
            enabled: false
        }
    ]);

    const canEnableUrls = useCallback((values: string[]) => {
        return Array.isArray(values) ? values?.some(value => value === "authorization_code" || value === "implicit") : false;
    }, []);

    const canShowUrls = useCallback(canEnableUrls, []);

    const canEnableSAML = useCallback((values: string[]) => {
        return Array.isArray(values) ? values?.some(value => value === "saml") : false;
    }, []);

    const canShowSAML = useCallback(canEnableSAML, []);

    const makeUrls = useCallback((urlString?: string) => urlString?.split(",").map(value => String(value).trim()).filter(value => value.length > 0) ?? [], []);

    const authUrlValidator = useCallback(() => (value: string) => {
        if (typeof value === "string") {
            const values = mapToArray(value.split(","));
            if(values.length > 250) {
                return "The number of urls exceed the max limit i.e. 250 ";
            }
            if (values.map(item => String(item).trim()).filter(item => item.length > 0).every(item => {
                try {
                    const url = new URL(item);

                    // https://jira.trimble.tools/browse/TCAC-1333
                    // allow queryparams for callback and logout urls
                    // if (url.href.includes("?")) {
                    //     return false;
                    // }

                    // if (url.href.includes("#")) {
                    //     return true;
                    // }

                    if (!url.protocol || !url.origin) {
                        return false
                    }

                    return true;
                } catch { }

                return false
            })) {
                return;
            }
        }

        return "Invalid input";
    }, []);



    return {
        authenticationMethods,
        applicationTypes,
        canShowUrls,
        canEnableUrls,
        makeUrls,
        authUrlValidator,
        protocolMethods,
        canEnableSAML,
        canShowSAML,
        availableScopeValidationMethods
    }
}

const useGrantTypeStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        margin: theme.spacing(-0.5, 0, 0, -0.5)
    },
}));

const createGrantTypes = (authenticationMethods: Array<{
    title: string,
    value: string
}>, publishedInfo?: IdentityApplicationPublishedGateway) => {
    return authenticationMethods.reduce((agg: string[], option) => {
        const hasOption = publishedInfo?.grantTypes?.find(value => value === option.value);

        if (hasOption) {
            agg.push(option.title);
        }
        return agg;
    }, []);
}

export const ApplicationGrantTypeViewer: FC<{
    publishedInfo?: IdentityApplicationPublishedGateway
}> = ({ publishedInfo }) => {
    const styles = useGrantTypeStyles();
    const { authenticationMethods } = useApplicationConfigurationInfo();

    const [titles, setTitles] = useState(() => createGrantTypes(authenticationMethods, publishedInfo));

    useEffect(() => {
        setTitles(createGrantTypes(authenticationMethods, publishedInfo))
    }, [authenticationMethods, publishedInfo]);

    return (
        <NAWrap value={titles} showMessage={true}>
            <div className={styles.root}>
                {
                    titles.map(title => <Chip key={title} label={title} />)
                }
            </div>
        </NAWrap>
    );
}

export const ApplicationGrantTypeView: FC<{
    applicationType?: IdentityApplicationTypes
}> = ({ applicationType }) => {
    return applicationType === "service-application" ? (
        <FormattedMessage defaultMessage="Service Application grant types" />
    ) : (
        <FormattedMessage defaultMessage="Application grant types" />
    );
}

export const ApplicationDetailTypeView: FC<{
    applicationType?: IdentityApplicationTypes
}> = ({ applicationType }) => {
    return applicationType === "service-application" ? (
        <FormattedMessage defaultMessage="Service Application" />
    ) : (
        <FormattedMessage defaultMessage="Application" />
    );
}
