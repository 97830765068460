import { Box, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import BookmarkIcon from "@material-ui/icons/Bookmark";

export const SubscriptionNavigation: React.FC = () => {
    const { push } =  useHistory();
    return (
        <>
            <ListItem
            button
            onClick={() => push("/home/subscriptions")}
            >
            <ListItemIcon>
                <BookmarkIcon style={{ color: "rgba(255, 255, 255, 0.72)" }} />,
            </ListItemIcon>
            <ListItemText
                className="sub-menu-text"
                primary={<Box display="flex"><Box flexGrow={1}>Subscriptions</Box>
                        </Box>}
            />
            </ListItem>
        </>
        
    )
}
