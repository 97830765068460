import React, { useCallback, memo, useRef } from 'react'
import { Tooltip, Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl';
import { ResourceAbility } from 'libs/security/authorization';
import NameSpaceDialog, { NameSpaceDialogRef } from './NameSpaceDialog';

function NameSpaces({ sectorName }: any) {
    const nameSpaceDialogRef = useRef<NameSpaceDialogRef>();
    const handleClickOpen = useCallback(() => nameSpaceDialogRef.current?.open(), []);

    return (
        <ResourceAbility can="create" resource={({ TeamMembers }) => TeamMembers.create}>
            <>
                <NameSpaceDialog ref={nameSpaceDialogRef} sectorName={sectorName} />
                <Tooltip title={<FormattedMessage defaultMessage="Namespaces" />}>
                    <Button variant="contained" color="primary" className="mr-1" onClick={handleClickOpen}>
                        <FormattedMessage defaultMessage="Namespaces" />
                    </Button>
                </Tooltip>
            </>
        </ResourceAbility>
    )
}

export default memo(NameSpaces);


// 
