import GenericPageTitle from "legacy-components/typography/generic-page-title/GenericPageTitle";
import SubscriptionService, { IAPIApplicationSubscription } from "libs/resources/subscription/SubscriptionService";
import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab } from "@material-ui/core";
import { ProductSubscriptionListAccordion } from "./ProductSubscriptionListAccordian";
import { productToApplicationMap } from "legacy-components/Helper";
import { Skeleton } from "@material-ui/lab";

export interface IApplicationSubscriptionList{
  applicationName: string;
  consumerKey: string;
  gatewayName: string;
  status: string;
  subscriberEmail: string;
  subscriptionId: string;
  subscriptionRequestedTime: string;
  productTeamName: string;
}

export interface ITransformedSubscriptionList{
  productName: string;
  applications: Array<IApplicationSubscriptionList>;
}
export const ProductSubscriptionList: React.FC = () => {

    const [preProdData, setPreProdData] = useState<Array<IAPIApplicationSubscription | null>>();
    const [prodData, setProdData] = useState<Array<IAPIApplicationSubscription | null>>();
    const subscriptionLevel= 'team';

      interface TabPanelProps {
        children?: React.ReactNode;
        index: any;
        value: any;
      }

      function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                {children}
              </Box>
            )}
          </div>
        );
      }

      const [value, setValue] = React.useState(0);
      const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
      };

    useEffect(() => {
          let filterSelf = false;
          void SubscriptionService.getTeamSubScriptions(filterSelf, "trimble-pre-prod")
            .toPromise()
            .then((output) => {
              setPreProdData(output);
            });
            void SubscriptionService.getTeamSubScriptions(filterSelf, "trimble-prod")
            .toPromise()
            .then((output) => {
              setProdData(output);
            });
      }, [subscriptionLevel]);

    const controls = (data: Array<ITransformedSubscriptionList> | undefined) => {
      return (
        <>
              {data === undefined && <Skeleton
              animation="wave"
              height="100px"
              width="100%"
            />}
             <ProductSubscriptionListAccordion data={data}></ProductSubscriptionListAccordion>
             </>
      )}

    return (
        <>
        <GenericPageTitle
          title={"Subscriptions"} subTitle={"View and configure Project Subscriptions"} />
         <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Pre Prod"   id= "simple-tab-0" aria-controls= "simple-tabpanel-0"/>
          <Tab label="Prod"   id= "simple-tab-1" aria-controls= "simple-tabpanel-1"/>

        </Tabs>
        <TabPanel value={value} index={0}>
          {controls(productToApplicationMap(preProdData))}
          </TabPanel>
          <TabPanel value={value} index={1}>
          {controls(productToApplicationMap(prodData))}
          </TabPanel>
        </>

      );
};