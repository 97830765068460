import { FormControlLabel, Radio, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import Badge from "legacy-components/badge/Badge";
import { formatApplicationType, resolveDate, resolveText } from "legacy-components/Helper";
import Table, { TableColumn } from "legacy-components/table/Table";
import React, { useState, useEffect } from "react";
import { IApplication, ProductService, IProductDetail } from "../../../service/ProductService";
import { resolveGatewayType } from "../../../utils/TextFormatters";
import { useStatusBadgeSyles } from "../../subscriptions/subscription-list/SubscriptionList.style";
import type { ISubscriptionStatus } from "../../subscriptions/subscription.interface";

/* eslint-disable-next-line */
export interface ApplicationListProps {
  product: IProductDetail;
  onApplicationSelected?: (selectedApplication: IApplication) => void;
}

export const ApplicationList: React.FC<ApplicationListProps> = ({
  product,
  onApplicationSelected,
}) => {
  const [data, setData] = useState<Array<IApplication | null>>();

  useEffect(() => {
    if (product.productName) {
      void ProductService.getApplications(product.productName, "UNSUBSCRIBED")
        .toPromise()
        .then((output) => {
          setData(output.data as IApplication[]);
        });
    }
  }, [product.productName]);

  // To display Environment status as Badge format
  const StatusBadge: React.FC<{ value: ISubscriptionStatus }> = ({ value }) => {
    const classes = useStatusBadgeSyles();
    return (
      <Badge
        label={resolveGatewayType(value)}
        className={clsx(classes.status, classes[value])}
      />
    );
  };

  /** To select a single application */
  const Selection = (props: { data: IApplication }) => {
    return (
      <FormControlLabel
        label=""
        onChange={() => {
          onApplicationSelected && onApplicationSelected(props.data);
        }}
        value={`${props.data.applicationName} ${props.data.environment}`}
        control={
          (product.publishedInfo.filter((info) => info.gateway === props.data.environment.toUpperCase()).length > 0 ?  
        <Radio color="primary" size="small" data-testid={`${props.data.applicationName}-radio-btn`}/>
          :
          <Radio disabled={true} color="primary" size="small" data-testid={`${props.data.applicationName}-radio-btn`}/>
          )
        }
      />
    );
  };

  return (
    <Table
    noRowsText={`You haven’t created any applications yet. To create a new application, go to Identity Management -> Applications`  }
      rowData={data}
      height="506px"
      frameworkComponents={{ StatusBadge, Selection }}
    >
      {onApplicationSelected && (
        <TableColumn
          field="actions"
          flex={0.5}
          cellRenderer="Selection"
          headerName=""
          tooltip = {(p: any) => {
            if(product.publishedInfo.filter((info) => info.gateway === p.data.environment.toUpperCase()).length > 0) {
             return "can subscribe";
            } else {
              return `The product is not published in ${resolveGatewayType(p.data.environment)} environment. Hence, Subscription with ${resolveGatewayType(p.data.environment)} applications cannot be done.`
            }
        }}
        />
      )}
      <TableColumn
        field="applicationName"
        flex={1.2}
        cellRenderer="Title"
        headerName="Name"
        sortable
        unSortIcon = {true}
        filter= "agTextColumnFilter"
        filterParams= {{
            suppressAndOrCondition: true,
            defaultOption: 'contains',
            filterOptions: ['contains']
        }}
      />
      <TableColumn
        field="type"
        flex={1}
        sortable
        unSortIcon = {true}
        valueFormatter={(params) => formatApplicationType(resolveText(params.value))}
      />
      <TableColumn field="description" flex={2} />
      <TableColumn
        field="environment"
        flex={1}
        sortable
        unSortIcon = {true}
        filter= "agTextColumnFilter"
        filterParams= {{
            suppressAndOrCondition: true,
            defaultOption: 'contains',
            filterOptions: ['contains']
        }}
        cellRenderer="StatusBadge"
      />
      <TableColumn
        field="createdAt"
        flex={1}
        headerName="Date Created"
        valueFormatter={(params) => resolveDate(params.value)}
        sortable
        unSortIcon = {true}
      />
    </Table>
  );
};

export default ApplicationList;
