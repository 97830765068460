import { combineReducers } from "redux";
// import { reducer as oidcReducer } from 'redux-oidc';
import { routerReducer } from 'react-router-redux';
import initializer from "./initializer";
import proxyManager from "./proxyManager";
import formManager from "./formManager";
import userManager from "./userManager";
import teamManager from "./teamManager";
import environmentManager from "./environmentManager";
import { reducer as authenticator } from "redux-oidc";

// Combining all reducers to single root reducer
const rootReducer = combineReducers({
     auth: authenticator,
     routing: routerReducer,
     common: initializer,
     proxyManager: proxyManager,
     formManager: formManager,
     userManager: userManager,
     environmentManager: environmentManager,
     teamManager: teamManager
});

export default rootReducer;