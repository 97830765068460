import React, { FC, useContext, useState, useEffect } from 'react';
import FormContainer, { FormContainerSubmitHandler, FormContainerOptions } from 'legacy-components/form/container/FormContainer';
import { ProductUpdateFormData } from '../models/products.model';
import UnifiedAPIService, { UnifiedAPI } from 'services/UnifiedAPIService';
import FormInputContainer from 'legacy-components/form/container/FormInputContainer';
import FormSelectionList from 'legacy-components/form/input/FormSelectionList';
import { Typography, Button, Snackbar, FormControl, InputLabel, Select, Chip, MenuItem, Checkbox, ListItemText, makeStyles, Theme, createStyles } from '@material-ui/core';
import ActionInliner from 'legacy-components/form/action/ActionInliner';
import { FieldArray } from 'react-final-form-arrays';
import FormSelectionGroup from 'legacy-components/form/input/FormSelectionGroup';
import { Field, FormRenderProps } from 'react-final-form';
import { useProductDetailInfo } from './ProductDetailInfo';
import { FormattedMessage, useIntl } from 'react-intl';
import FormTextInput from 'legacy-components/form/input/FormTextInput';
import { ValidatorBuilderContext } from 'legacy-components/form/validator/Validator';
import { useHistory } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import ProductService from 'libs/resources/product/ProductService';
import { OnChange } from 'react-final-form-listeners';
import { sanitizeText } from './FileXSSRemover';

interface ProductUpdateFormProps {
    productName: string
    initialValues: any
    stepperId: string
    onSuccess: (data?: any) => void

}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: "100%",
            maxWidth: "auto",
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);


const ProductUpdateForm: FC<ProductUpdateFormProps> = ({ stepperId, productName, initialValues, onSuccess }) => {
    let history = useHistory();
   
    type alertType = "success" | "error" | "warning" | undefined;
    const [apiMessage, setApiMessage] = useState<string | undefined>();
    const [apiMessageType, setApiMessageType] = useState<alertType>();
    const [apiType, setapiType] = React.useState<string[]>(initialValues?.apiTypeMapping);

    const onSubmit: FormContainerSubmitHandler<ProductUpdateFormData> = () => (data, form) => {
        return new Promise((resolve) => {
            const formState = form.getState();


            if (formState.pristine) {
                resolve();
            } else {

                UnifiedAPIService.put(
                    UnifiedAPI.parseRequestURL(UnifiedAPI.edit_product_api, {
                        id: productName
                    }), {
                    displayName: sanitizeText(data.displayName) ?? undefined,
                    shortDescription: sanitizeText(data.shortDescription) ?? undefined,
                    longDescription: sanitizeText(data.longDescription) ?? undefined,
                    visibility: data.visibility ?? undefined,
                    iso: data.compliance?.some(value => value === "iso") ?? false,
                    soc2: data.compliance?.some(value => value === "soc2") ?? false,
                    soc3: data.compliance?.some(value => value === "soc3") ?? false,
                    tsdcl: data.compliance?.some(value => value === "tsdcl") ?? false,
                    apiTypeMapping: apiType ?? undefined,
                }).then(() => {
                    setApiMessage(" Product Updated")
                    setApiMessageType("success");
                    setTimeout(() => {
                        // history.go(0);
                        onSuccess && onSuccess();
                    }, 2000);
                    resolve();

                },
                    error => {
                        setApiMessage(error.response?.data?.message);
                        setApiMessageType("error");
                        resolve({
                        });


                    });

            }
        })
    }

    const FormComponents = (formRenderProps: FormRenderProps<{}, {}>, containerOptions: FormContainerOptions) => {
        const { compilanceOptions, visibilityOptions } = useProductDetailInfo();
        const intl = useIntl();
        const validator = useContext(ValidatorBuilderContext);
        const onSnackBarClose = () => {
            setApiMessage(undefined);
            setApiMessageType(undefined);
        }
        const classes = useStyles();
        const [names, setNames] = useState<Array<string> | null>(null);

        useEffect(() => {
            ProductService.getApiTypes().toPromise()
                .then(
                    (output) => {
                        setNames(output);
                    },
                    (error) => {
                    });
        }, [])


        const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
            setapiType(event.target.value as string[]);
        };

        const handleDelete = (chipToDelete: string) => () => {
            setapiType((chips) => chips.filter((chip) => chip !== chipToDelete));
          };

        const [remainingCount, setRemainingCount] = useState(140);
        const onShortDescriptionChange = (shortDescription: string) => {
            if (shortDescription.length <= 140)
                setRemainingCount(140 - shortDescription.length);
            else
                setRemainingCount(0);        
        } 
        return (<>
            <Snackbar autoHideDuration={5000} open={apiMessage ? true : false} onClose={onSnackBarClose}>
                <Alert severity={apiMessageType}>{apiMessage}</Alert>
            </Snackbar>
            <FormInputContainer padding="inset">
                <Field
                    name="displayName"
                    type="text"
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Display Name"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Display Name"
                    })}
                    validate={validator.from({
                        minLength: 3
                    })}
                />

            </FormInputContainer>
            <FormInputContainer padding="inset">
            <Typography variant = "body2">Remaining characters : {remainingCount}</Typography>
            <br></br>
                <Field
                    name="shortDescription"
                    type="text"
                    multiline
                    rows={3}
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Short Description"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Short Description"
                    })}
                    validate={validator.from({
                        maxLength: 140
                    })}
                />
                <OnChange name="shortDescription">
                    {(value: any) => onShortDescriptionChange(value)}
                </OnChange>
            </FormInputContainer>
            
            <FormInputContainer padding="inset">
                <Field
                    name="longDescription"
                    type="text"
                    multiline
                    rows={6}
                    component={FormTextInput}
                    label={intl.formatMessage({
                        defaultMessage: "Long Description"
                    })}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Long Description"
                    })}
                    validate={validator.from({
                        maxLength: 1200
                    })}
                />
            </FormInputContainer>
            <FormInputContainer padding="inset">
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-chip-label">API Type</InputLabel>
                    <Select

                        id="apiTypes"
                        multiple
                        onChange={handleChange}
                        renderValue={() => <div className={classes.chips}>
                            {
                                <Chip key="no" label={`${apiType.length} selected`} className={classes.chip} />
                            }
                        </div>}
                        value={apiType}
                    >
                        {names?.map((name) => (
                            <MenuItem key={name} value={name} >
                                <Checkbox checked={apiType.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                    <div className={classes.chips}>
                        {apiType?.map((value) => (
                            <Chip key={value} label={value} className={classes.chip} onDelete={handleDelete(value)} />
                        ))}
                    </div>
                </FormControl>
            </FormInputContainer>

            <FormInputContainer padding="inset" title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Visibility" />} />}>
                <Field
                    name="visibility"
                    children={FormSelectionGroup}
                    options={visibilityOptions}
                    defaultSelectionIndex={0}
                />
            </FormInputContainer>
            <FormInputContainer padding="inset" title={<Typography variant="subtitle2" children={<FormattedMessage defaultMessage="Compliance" />} />}>
                <FieldArray
                    name="compliance"
                    children={FormSelectionList}
                    options={compilanceOptions}
                />
            </FormInputContainer>
            <ActionInliner padding="inset">
                {containerOptions.stepperOptions.navigateBack && <Button onClick={containerOptions.stepperOptions.navigateBack} disabled={formRenderProps.submitting} type="button" variant="outlined" disableElevation> <FormattedMessage defaultMessage="Back" /></Button>}

                <Button disabled={formRenderProps.submitting} type="submit" variant="contained" color="primary" disableElevation> <FormattedMessage defaultMessage="Update" /></Button>
            </ActionInliner>
        </>);
    }

    return <FormContainer prefillValues={initialValues} stepperId={stepperId} FormProps={{ onSubmit }} onSubmitSuccess={onSuccess} children={FormComponents} previewErrors={true} />;
}


export default ProductUpdateForm;