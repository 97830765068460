import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { FormattedMessage } from "react-intl";
import GenericPageHeader from '../../../legacy-components/page-headers/GenericPageHeader';
import Loader from "../../../legacy-components/loader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>
            {children}
          </Box>
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
}));

FullWidthTabs.propTypes = {
  teamProxies: PropTypes.any,
  sharedProxies: PropTypes.any,
  header: PropTypes.any
}

export default function FullWidthTabs(props) {
  const { teamProxies, sharedProxies, header } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <GenericPageHeader  title={<FormattedMessage defaultMessage="API Proxies" />} controls={header} />
      <Tabs
        value={value}
        onChange={handleChange}
      >

        <Tab label={<FormattedMessage defaultMessage="Team" />} />
        <Tab label={<FormattedMessage defaultMessage="Shared" />} />

      </Tabs>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <br></br>
          <div className="proxy-list">
            <div className="team-api-list">
              {!teamProxies? <div className="proxy-loader"><Loader/></div> : teamProxies}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <br></br>
          <div className="sharedproxy-list">
            <div className="shared-api-list">
              {sharedProxies}
            </div>
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
