import React, { useState, useRef, RefObject, useCallback, useEffect, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Typography, Paper, Tooltip, Button } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { useAsyncAction } from '../../../legacy-components/form/action/AsyncAction';
import clsx from 'clsx';
import { useGridStyles } from '../../../legacy-components/grid/GridStyle';
import { useRole, useFeatureAbility } from 'libs/security/authorization/Permission';
import { useHistory, withRouter } from "react-router-dom";
import { connect, ConnectedProps } from 'react-redux';

import { useConfirm } from "material-ui-confirm";
import Loader from "../../../legacy-components/loader";
import FormAlert from 'legacy-components/form/container/FormAlert';
import { GridViewApprovalStatusBadgeCellRenderer } from 'legacy-components/grid-view/GridView';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import GenericPageHeader from 'legacy-components/page-headers/GenericPageHeader';
import SubscriptionService, { IAPIProductSubscription } from 'libs/resources/subscription/SubscriptionService';
import { ExportAsCSV } from './ExportAsCSV';
import { SnackNotificationContext } from 'legacy-components/notifications/GenericSnackNotification';
import Alert from '@material-ui/lab/Alert';
import  { Feature } from 'libs/security/authorization/FeatureAbility';
import ProductService from 'libs/resources/product/ProductService';

enum FrameworkComponentsType {
    LoadingOverlay = "LOADING_OVERLAY",
    NoRowsOverlay = "NO_ROWS_OVERLAY",
    RowData = "ROW_DATA",
    RowActions = "ROW_ACTIONS"
}
const mapState = () => ({

});

const connector = connect(mapState);

type FrameworkComponentState = { [K in FrameworkComponentsType]: (...params: any) => JSX.Element };

interface IGripOverlayPropRef {
    error: boolean;
}

const ProductSubscriptions = (props: any) => {
    const intl = useIntl();
    const gridStyle = useGridStyles();
    const [, state] = useRole();
    const [isFormCompleted, setIsFormCompleted] = useState<string>();

    const LoadingComponent = () => <Loader />;
    const NoRowsComponent = (props: {
        gridOverlayPropRef: RefObject<IGripOverlayPropRef>
    }) => {
        return (
            <Typography variant="body2">
                {props.gridOverlayPropRef?.current?.error ? <FormattedMessage defaultMessage="No Subscriptions available" /> : <FormattedMessage defaultMessage="No Subscriptions available" />}
            </Typography>
        );
    };

    const DataComponent = (data: any) => {
        return (
            <div>
                {data.name}
            </div>
        );
    }
    const notificationContext = useContext(SnackNotificationContext);
    const SubcribedMessage = (props: any) => {
        return (
            <span>Subscription Enabled</span>
        )
    }
    const RevokedMessage = (props: any) => {
        return (
            <span>Subscription Revoked</span>
        )
    }
    const getRowNodeId = (data: {
        id: string
    }) => data.id;
    const onSuccess = () => {
        setIsFormCompleted(Math.random().toString());
    }
    
    const updateSubscription = (subscriptionId: string, action: any) => {
        return new Promise((resolve) => {
            ProductService.updateSubscriptionStatus(subscriptionId, action
                ).subscribe(() => {
                    if (action === 'APPROVE') {
                        notificationContext.show({
                            timeout: 6000,
                            id: "approve success",
                            message: <SubcribedMessage/> 
                        });
                    }
                    else if (action === 'REVOKE') {
                        notificationContext.show({
                            timeout: 6000,
                            id: "revoke success",
                            message: <RevokedMessage/> 
                        });
                    }
                    onSuccess();
                })
        })
    }

    const RowActionComponent = (props: {
        data: any
    }) => {
        if (props.data.status == 'SUBSCRIBED') {   
            return (   
                <React.Fragment>
                    <Tooltip title={<FormattedMessage defaultMessage="Revoke Subscription" />}>
                        <RemoveCircleOutlineIcon onClick = {()=> updateSubscription(props.data.subscriptionId, "REVOKE")}/> 
                    </Tooltip>
                </React.Fragment>
            );
        }
        else {
            return (   
                <React.Fragment>
                    <Tooltip title={<FormattedMessage defaultMessage="Enable Subscription" />}>
                    <CheckCircleOutline onClick = {()=> updateSubscription(props.data.subscriptionId, "APPROVE")}/> 
                    </Tooltip>
                </React.Fragment>
            );
        }
    };

    const [frameworkComponents] = useState<FrameworkComponentState>({
        [FrameworkComponentsType.LoadingOverlay]: LoadingComponent,
        [FrameworkComponentsType.NoRowsOverlay]: NoRowsComponent,
        [FrameworkComponentsType.RowData]: DataComponent,
        [FrameworkComponentsType.RowActions]: RowActionComponent
    });

    const [gridApi, setGridApi] = useState<GridApi | undefined>();

    let [collection, { subscribe }] = useAsyncAction<IAPIProductSubscription[]>(() =>{
       return ProductService.getSubscriptions(props.match.params.productName, "detailed")
    }
  );

  useEffect(() => {
    let unsubscribe = () => { };
    if (state.loaded || state.intermediate) {
      unsubscribe = subscribe();
    }
    return () => unsubscribe();
  }, [isFormCompleted, subscribe, state.loaded, state.intermediate]);

  const onGridReady = (event: GridReadyEvent) => setGridApi(event.api);
  useEffect(() => {
    if (gridApi) {
      gridOverlayPropRef.current.error = collection.error;

      if (collection.error) {
        gridApi.setRowData([]);
      } else if (collection.value) {
        gridApi.setRowData(processSubscriptionInfo(collection.value));
      }
    }
  }, [collection, gridApi]);

  const processSubscriptionInfo = (subscriptions: IAPIProductSubscription[]) => {
    return subscriptions.map(item => {
        return {
            subscriptionId : item.subscriptionId,
            applicationName: item.applicationName,
            subscriberEmail: item.subscriberEmail,
            gatewayName : item.gatewayName === "trimble-prod" ? "prod" : "pre-prod",
            teamName: item.teamName,
            sectorName : item.sectorName,
            status : item.status? item.status : "SUBSCRIBED",
            consumerKey: item.consumerKey
        }
    });
  }

    type alertType = "success" | "error" | "warning" | undefined;
    const [apiMessage, setApiMessage] = useState<string | undefined>();
    const [apiMessageType, setApiMessageType] = useState<alertType>();
    
    const [columnDefs] = useState<ColDef[]>(() => {
        const columns: ColDef[] = [{
            field: "applicationName",
            sortable: true,
            filter: "agTextColumnFilter",
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Application Name"
            }),
            flex: 0.7,
            resizable: true,
        }, {
            field: "consumerKey",
            sortable: true,
            filter: "agTextColumnFilter",
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Consumer Key"
            }),
            flex: 0.7,
            resizable: true,
        }, {
            field: "gatewayName",
            sortable: true,
            filter: "agTextColumnFilter",
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Environment"
            }),
            flex: 0.4
        },{
            field: "status",
            headerName: intl.formatMessage({
                defaultMessage: "Status"
            }),
            cellRendererFramework: GridViewApprovalStatusBadgeCellRenderer,
            flex: 0.5
        },{
            field: "subscriberEmail",
            sortable: true,
            filter: "agTextColumnFilter",
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Email"
            }),
            flex: 0.8,
            resizable: true,
        }, {
            field: "teamName",
            sortable: true,
            filter: "agTextColumnFilter",
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Team"
            }),
            flex: 0.5,
            resizable: true,
        }, {
            field: "sectorName",
            sortable: true,
            filter: "agTextColumnFilter",
            filterParams: {
                suppressAndOrCondition: true,
                defaultOption: 'contains',
                filterOptions: ['contains']
            },
            comparator: (valueA, valueB) => { return valueA.toLowerCase().localeCompare(valueB.toLowerCase()); },
            headerName: intl.formatMessage({
                defaultMessage: "Sector"
            }),
            flex: 0.5,
            resizable: true,
        }
    ];
   
    columns.push( {
        field: "actions",
        headerName: "",
        flex: 0.3,
        cellRenderer: FrameworkComponentsType.RowActions
    });
   

        return columns;
    });

    
   
    const gridOverlayPropRef = useRef<IGripOverlayPropRef>({
        error: false
    });

    const onCellClicked = useCallback((event: any) => {
    }, []);

    const showMessage = useCallback((message: string, type?: string) => {
        if (!type) {
            type = "success";
        }
        if (message) {
            setApiMessage(message);
            setApiMessageType(type as ("success" | "error"));
            setTimeout(() => {
                hideMessage(null);
            }, 6000);
        }
    }, [setApiMessageType, setApiMessage]);

    const hideMessage = useCallback((event) => {
        event?.preventDefault();
        event?.stopPropagation();
        setApiMessage("");
        setApiMessageType(undefined);
    }, [setApiMessageType, setApiMessage]);

    const ExportAsCSVButton = () =>{
        return (
                <Button  
                variant="contained" color="primary" onClick = {()=> ExportAsCSV(collection.value, "file")}>
                    <FormattedMessage defaultMessage="Export As CSV" />
                </Button>
        )}
    return (
        <>
        <GenericPageHeader title={<FormattedMessage defaultMessage="Product Subscriptions" />} 
            controls = {<ExportAsCSVButton/>}/>
            <Alert severity="info">
                <b>Note:</b> The Subscriptions view displays only Trimble Identity v4.0 applications
            </Alert>
            <br/>
            <Paper elevation={2} className={clsx("ag-theme-alpine", gridStyle.root, `${gridStyle.header}--tinted`, gridStyle.clickable)}>

                {apiMessageType && (<FormAlert severity={apiMessageType as "success" | "error" | "info"}>{apiMessage}</FormAlert>)}
                {collection &&
                    <AgGridReact
                        columnDefs={columnDefs}
                        frameworkComponents={frameworkComponents}
                        loadingOverlayComponent={FrameworkComponentsType.LoadingOverlay}
                        noRowsOverlayComponent={FrameworkComponentsType.NoRowsOverlay}
                        noRowsOverlayComponentParams={{ gridOverlayPropRef }}
                        suppressCellSelection={true}
                        suppressRowHoverHighlight={true}
                        getRowNodeId={getRowNodeId}
                        undoRedoCellEditing={true}
                        onGridReady={onGridReady}
                        enableCellTextSelection={false}
                    />
                }

            </Paper>

        </>
    )
}
export default withRouter(connector(ProductSubscriptions));
